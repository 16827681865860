import React, { useState, useEffect } from "react";
import { Image, InputNumber, message,Input,Form,Button,Modal,Table,Select,Option } from "antd";
// import 'antd/dist/antd.css';
import { Col, Row } from "antd";
import "./EventsFacilityBookng.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { CloseOutlined,EditOutlined } from '@ant-design/icons';
import { DatePicker,Radio } from "antd";
// import { TimePicker } from "antd";
import moment from "moment";
import country_codes from "../../MembershipForm/countryCodes";
import { capitalize } from "@mui/material";
const { RangePicker } = DatePicker;
function EventsFacilityBooking() {
  const navigate = useNavigate();
  const location = useLocation();
  const [hrs, setHrs] = useState(0);
  const [mhrs, setMHrs] = useState(0);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const [amenties, setAmenties] = useState([]);
  const [bookingData, setBookingData] = useState({});
  const storedToken = localStorage.getItem("token");
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [selectedHours, setSelectedHours] = useState(0);
  const [startdate, setStartdate] = useState("");
  const [starttime, setStarttime] = useState("");
  const [enddate, setEnddate] = useState("");
  const [endtime, setEndtime] = useState("");
  const [guest, setGuest] = useState(0);
  const [total, setTotal] = useState(null);
  const [child, setChild] = useState(null);
  const [adult, setAdult] = useState(null);
  const [startSlots,setStartSlots]=useState([]);
  const [typeForGuest,setTypeForGuest]=useState([]);
  const [endSlots,setEndSlots]=useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleForGuestSelection,setVisibleForGuestSelection]=useState(false);
  const [visibleForm,setVisibleForm]=useState(false);
  const [data,setData]=useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bookButton,setBookButton]=useState(true);
  const [phoneCode,setPhoneCode]=useState("+65");
  const [tableId,setTableId]=useState();
  const [form] = Form.useForm();
  const [allSlots,setAllSlots]=useState([]);
  const [disabledDateArray,setDisabledDateArray]=useState();

  const handleTypeForGuestChange = (value)=>{
    if(form.getFieldValue("age")>=18 && value!=="children"){
      setTypeForGuest(value);
    }
    else{
      message.warn("Please change the age");
    }
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
    },
    {
      title:'type',
      dataIndex:'type',
      render:(record)=>{
        return(record==="spouser"?"Spouse":capitalize(record))
      }
    },
    {
      title:'Action',
      render: (record) => {
        return (
          <EditOutlined onClick={()=>formEdit(record)}/>
          // <div>{moment(record?.start_time, "HH:mm").format("hh:mm A")} - {moment(record?.end_time, "HH:mm").format("hh:mm A")}</div>
        )
      }
    }
  
  ];

  const formEdit = (record)=>{
    setVisibleForm(true);
    form.setFieldsValue({
      "name":record.name,
      "age":record.age,
      // "type":record.type,
      "IC":record.ic_number,
      "phone":record.phone,
      "email":record.email,
    })
    setTypeForGuest(record.type);
    setPhoneCode(record.country_code)
    setTableId(record.id)
  }
  const getTableData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/guest`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      // console.log(response.data.result);
      const data = response.data.result.map((item)=>({
        ...item,
        key:item.id
      }))
      setData(data);
      // console.log(data)
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };
  
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisibleForm(false)
  };

  const hideModalForGuestSelection = () => {
    setVisibleForGuestSelection(false);
  };

  const submitForm = (values)=>{
    if(values.isGuest==="notGuest"){
      redirectiontoBooking(values.isGuest);
    }
    else{
      getTableData();
      setVisibleForGuestSelection(false);
      setVisible(true)
    }
    // console.log(values);
  }
  const onFinish = async (values) => {
    //
    // console.log(values); 
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/guest/add`,
        {
          "name":values.name,
          "email":values.email,
          "phone":values.phone,
          "country_code":phoneCode,
          "age":values.age.toString(),
          "type":typeForGuest,
          "ic_number":values.IC.toString(),
          "id":tableId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      getTableData();
      setVisibleForm(false);
      form.resetFields();
      setTableId(null);
      setVisible(true);
    } catch (error) {
      // Handle error
      console.error(error);
    }
    // 
    // Handle form submission here
    hideModal();
  };
  const handleButtonClick = (item,buttonId) => {
    if(item.is_available){
    setSelectedButton(buttonId);
    setStartSlots(item.start);
    setEndSlots(item.end);
    }
    else{
      setSelectedButton(null);
    }
  };
  const stdate = moment(bookingData.start_date);
  const stdayOfWeek = stdate.format("ddd");
  const eddate = moment(bookingData.end_date);
  const eddayOfWeek = eddate.format("ddd");
  const stime = moment(bookingData.start_time, "HH:mm:ss");
const sttime = stime.format("h:mm a");
const etime = moment(bookingData.end_time, "HH:mm:ss");
const edtime = etime.format("h:mm a");
const [startTime, setStartTime] = useState(null);
const [endTime, setEndTime] = useState(null);
  const handleTimeChange = (times) => {
    const [start, end] = times;
    console.log(mhrs,"mhrs");
    if (mhrs===0 || hrs===mhrs) {
    setStartTime(start);
    if (start) {
      const endTime = start.clone().add(hrs, "hours");
      setEndTime(endTime);
    } else {
      setEndTime(null);
    }
    console.log(times);
    const startTime = moment(times[0]);
    const endTime = moment(times[1]);

    const startISO = startTime.format("HH:mm:ss");
    const endISO = endTime.format("HH:mm:ss");
    setStarttime(startISO);
    setEndtime(endISO);
    console.log("Start time (ISO):", startISO);
    console.log("End time (ISO):", endISO);

    if (times[0]) {
      const calculatedEndTime = moment(times[0]).add(hrs, "hours");
      const duration = moment.duration(calculatedEndTime.diff(times[0]));
      const hours = Math.floor(duration.asHours());
      console.log("End time:", calculatedEndTime.format("HH:mm:ss"));
      console.log("Duration (hours):", hours);
      setSelectedHours(hours);
      setEndtime(calculatedEndTime.format("HH:mm:ss"));
    } else {
      setSelectedHours(0);
      setEndtime("");
    }
    
      return
    }
    // Check if both start and end times are selected
    if (start && end) {
      const duration = end.diff(start, 'hours'); // Calculate the duration in hours
  
      if (duration >= hrs && duration <= mhrs) {
        // Valid range: 1 hour to 3 hours
        setStartTime(start);
        setEndTime(end);
      } else {
        // Invalid range
        // You can handle the error or display a message to the user
        message.error(`Invalid time range. Please select a range between ${hrs} hour and ${mhrs} hours.`)
        console.log('Invalid time range. Please select a range between 1 hour and 3 hours.');
      }
    } else {
      // Handle the case where either start or end time is not selected
      setStartTime(start);
      setEndTime(end);
    }
  };
  
  
  const disabledTime = (_, type) => {
    const startTime = moment(bookingData.start_time, "HH:mm:ss");
    const endTime = moment(bookingData.end_time, "HH:mm:ss");

    if (type === "start") {
      return {
        disabledHours: () =>
          generateDisabledHours(startTime.hour(), (endTime.hour()-hrs)),
        disabledMinutes: () => generateDisabledMinutes(startTime, endTime),
      };
    }
    if (type === "end") {
      return {
        disabledHours: () =>
          generateDisabledHours((startTime.hour()+hrs), endTime.hour()),
        disabledMinutes: () => generateDisabledMinutes(startTime, endTime),
      };
    }

    return {};
  };

  const generateDisabledHours = (startHour, endHour) => {
    const hours = [];

    console.log(startHour, endHour);
    for (let hour = 0; hour < 24; hour++) {
      if (hour < startHour || hour > endHour) {
        hours.push(hour);
      }
    }

    return hours;
  };

  const generateDisabledMinutes = (startTime, endTime) => {
    const disabledMinutes = [];

    if (startTime.hour() === endTime.hour()) {
      for (let minute = 0; minute < 60; minute++) {
        if (minute < startTime.minute() || minute > endTime.minute()) {
          disabledMinutes.push(minute);
        }
      }
    }

    return disabledMinutes;
  };


  const dayNameToNumber = (dayName) => {
    
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysOfWeek.indexOf(dayName.trim());
  };

  const disabledDate = current => {
    // var a = bookingData.closed.split("-");
    // const dayNumbersArray = [];
    // a.map((item)=>{
    //   dayNumbersArray.push(dayNameToNumber(item))
    // })

    // const newArray = [];
    // while(dayNumbersArray[0]!==dayNumbersArray[1]){
    //   newArray.push(dayNumbersArray[0]);
    //   if(dayNumbersArray+1>6){
    //     dayNumbersArray[0]=0;
    //   }
    //   else{
    //     dayNumbersArray[0]++;
    //   }
    // }
    // newArray.push(dayNumbersArray[0]);
    // console.log(newArray);
    // Disable dates that are before today
      // Disable dates that are before today
  
      if (current && current < moment().startOf("day")) {
        return true;
      }
      var flag = 0;
    // // Disable dates if they are Saturday or Sunday
    if(current && current >= moment().startOf("day")){
      disabledDateArray.map((item)=>{
      
        if(item===current.day()){
          flag = 1;
          
          // console.log("checking",item);
        }
      })
    }
    return flag;
    // return current && (newArray.map((item)=> current.day()===item))
    // return current && (disabledDateArray.map((item)=> current.day()===item))
    // return current && newArray.includes(current.day());
  };

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
    updateSlots(moment(dates).format("YYYY-MM-DD"));
    setStartdate(moment(dates).format("YYYY-MM-DD"));
    setEnddate(moment(dates).format("YYYY-MM-DD"));
    console.log(
      dates,
      moment(dates).format("YYYY-MM-DD")
      // moment(dates[1]).format("YYYY-MM-DD")
    );
    if (starttime && endtime && moment(dates).format("YYYY-MM-DD")) {
      let data = {
        id: id,
        start_date: moment(dates).format("YYYY-MM-DD"),
        start_time: starttime,
        end_time: endtime,
      };
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/bookings/get-availability`,
          data
        )
        .then((res) => {
          console.log(res);
          setTotal(res.data.total);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const booking = () => {
    const data = {
      user_id: "2",
      type: "event",
      type_id: "1",
      date: "2022-11-17",
      booking_hours: "1",
      no_of_pax: "1",
      amount: "100",
      start_date: "2023-04-23",
      end_date: "2023-04-24",
      start_time: "10:00:00",
      end_time: "11:00:00",
    };
    axios
      .post(`https://sia.reddotapps.com.sg/api/bookings/new`, data)
      .then((res) => {
        console.log(res.data.result);
        // id 1 for event facility
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSlots = (date)=>{
    try{ 
     axios({
       method:"post",
       url:`${process.env.REACT_APP_BASE_URL}/check`,
       data:{
           id:id,
           date:date
 
       },
 
     }).then((response)=>{
      if(response.data?.status == false){
        message.error(response.data.message);
        return;
      }
      const condition = response.data.every((item)=>{
        return item.is_available===0
      })
      setBookButton(!condition)
       console.log(response);
       setAllSlots(response.data)
       setSelectedButton(null);
     setStartSlots("");
     setEndSlots("");
       
     })
    }catch(err){
 
    } 
   }

   var startArray = [];
  var endArray=[];
  const calculateSlots = (start,end,gap)=>{
    setSelectedHours(gap);
    while(start<=end){
      
    var starts = start;
    
    start =  moment(start,"HH:mm:ss").add(gap,'hours').format("HH:mm:ss");
    if(start<=end){
      startArray.push(starts);
      endArray.push(start);
    }
      
    start = moment(start,"HH:mm:ss").add("15",'minutes').format("HH:mm:ss");  
    }
    setStartSlots(startArray);
    setEndSlots(endArray);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
    if (type === "event") {
      console.log(type);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/get-events?id=${id}`)
        .then((res) => {
          console.log(res.data.result);
          setBookingData(res.data.result[0]);
          // id 1 for event facility
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/get-facilities-types?id=${id}`
      )
      .then((res) => {
        setAmenties(JSON.parse(res.data.result[0].amenities));
        console.log(moment.duration(res.data.result[0].minimum_hours).asHours());
        setBookingData(res.data.result[0]);
        // id 1 for event facility
        // const startTime = moment("02:00:00", "HH:mm:ss");
        // const endTime = moment("04:00:00", "HH:mm:ss");
        const startTime = moment(res.data.result[0].start_time, "HH:mm:ss");
        const endTime = moment(res.data.result[0].end_time, "HH:mm:ss");
        const duration = moment.duration(endTime.diff(startTime));
        const hours = Math.floor(duration.asHours());

        // Format the hours as a single digit
        const formattedHours = parseInt(hours);
        console.log(res.data.result[0]);
        setHrs(moment.duration(res.data.result[0].minimum_hours).asHours());
        setMHrs(moment.duration(res.data.result[0].maximum_hours).asHours());
        setDisabledDateArray(res.data.result[0].closes_array);
        setSelectedHours(moment.duration(res.data.result[0].minimum_hours).asHours());
        // calculateSlots(res.data.result[0].start_time,res.data.result[0].end_time,moment.duration(res.data.result[0].minimum_hours).asHours());
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const redirectiontoBooking = (isGuest)=>{
    if(selectedRowKeys.length===0 && isGuest!="notGuest" ){
      message.warn("Please select row to Book")
      return;
    }
    navigate("/EventsFacilityCheckout", {
                  state: {
                  type_id: id,
                  type: type,
                  start_date: startdate,
                  end_date: startdate,
                  start_time: startSlots,
                  end_time: endSlots,
                  no_of_pax: selectedRowKeys && selectedRowKeys.length,
                  amount: storedToken
                    ? bookingData.fee_for_member * selectedHours
                    : bookingData.fee_for_non_member  * selectedHours,
                  discount: 0,
                  total_amount: storedToken
                    ? bookingData.fee_for_member * selectedHours
                    : bookingData.fee_for_non_member * selectedHours,
                  tax: 0,
                  booking_hours: selectedHours,
                  bookingName: bookingData.name,
                  guest:selectedRowKeys
                  // adult: adult,
                  // child: child,
                },
              });
  }

  const selectBefore = (
    <Select
      defaultValue={phoneCode}
      style={{
        width: "80px",
      }}
      onChange={(e) => setPhoneCode(e.target.value)}
    > {
      country_codes.map((elem,i)=>{
        return<><Select.Option key={i} value={elem.code}>{elem.code}</Select.Option></>
      })
    }
      {/* <Option value="+65">+65</Option>
      <Option value="+91">+91</Option> */}
    </Select>
  );
  return (
    <div className="marginTopFromNavbar" style={{ marginBottom: "100px" }}>
      {/* <div className="events_component_1">
        <div className="events_1">
          <h1>EVENTS FACILITY</h1>
        </div>
        <div className="events_2">Home - Bookings - Events Facility</div>
      </div> */}
      {/* <div className="header_new">
          <div className="text-container">
            <div className="text">EVENTS FACILITY</div>
            <div className="text2">Home - Bookings - Events Facility</div>
          </div> 
      </div> */}
      <div className="sportClub_head">
        <div>{bookingData.name}</div>
        <div>Unoccupied</div>
      </div>
      <div>
        <div className="sportClub_img">
          <Row className="gallery-imgs-row" gutter={8}>
            <Col span={12}>
              <Image
                style={{ width: "100%", height: "auto" }}
                className="large_img"
                src={bookingData.thumbnail}
              />
            </Col>
            <Col span={12}>
              <Row gutter={8}>
                {bookingData?.images &&
                  bookingData?.images.length !== 0 &&
                  bookingData?.images.slice(0, 4).map((elem, i) => {
                    return (
                      <Col span={12}>
                        <Image
                          style={{ width: "100%", height: "auto" }}
                          className="small_img"
                          src={elem.image_path}
                        />
                      </Col>
                    );
                  })}
                {/* <Col span={12}>
                  <Image
                    style={{ width: "100%", height: "auto" }}
                    className="small_img"
                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  />
                </Col>
                <Col span={12}>
                  <Image
                    style={{ width: "100%", height: "auto" }}
                    className="small_img"
                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  />
                </Col> */}
              </Row>
              {/* <Row gutter={8} style={{ marginTop: 8 }}>
                <Col span={12}>
                  <Image
                    style={{ width: "100%", height: "auto" }}
                    className="small_img"
                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  />
                </Col>
                <Col span={12}>
                  <Image
                    style={{ width: "100%", height: "auto" }}
                    className="small_img"
                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  />
                </Col>
              </Row> */}
            </Col>
          </Row>
        </div>
      </div>

      <div className="eventsFacilityBookingCard">
        <div className="EventsFacilityBooking_card_one">
          <div>
            <div className="sportsClub_desc_head events_facility_desc">
              Description
            </div>
            <div className="sportsClub_desc_info" style={{ fontSize: "16px" }}>
              {bookingData.description}
            </div>
          </div>

          <div className="SportsEvents_info">
            <div className="SportsEvents_info_head events_facility_desc">
              Booking Hours
            </div>
            <div className="FacilityEvent_info  ">
              <div className="FacilityEvent_timings">
                <div>Business Hrs</div>
                {/* {stdayOfWeek}
                {eddayOfWeek} */}
                <div>{bookingData.business_days} {bookingData.buiness_time}</div>
                <div>{bookingData.closed} Closed</div>
              </div>
              <div className="FacilityEvent_fees">
                <div>FEES</div>
                <div>S${bookingData.fee_for_member}.00 for members</div>
               {localStorage.getItem("status")!=="active" &&  <div>{bookingData.fee_for_non_member}$ for non members</div>}
              </div>
            </div>
            <div className="FaciltyEvent_booking_hrs FacilityEvent_fees">
              <div className="events_facility_hrs">Booking Hrs</div>
              <div>{moment.duration(bookingData.minimum_hours).asHours()} Hrs/Booking</div>
            </div>
          </div>
          {type !== "event" && (
            <div>
              <div className="events_amenties">Amenties we offer</div>
              <div className="events_amenties_card">
                <div className={`events_amenties_one ${amenties.length>4 && "d-flex flex-wrap"}`}>
                {amenties.length !== 0 &&
                    amenties.map((elem, i) => {
                      return (
                        <div className={amenties.length>4 && "w-50"}>
                          <img src="/assets/icons/tick.svg"></img>{elem}
                        </div>
                      );
                    })}
                  {/* <div>
                    <img src="/assets/icons/tick.svg"></img>Safety & Hygine
                  </div>
                  <div>
                    {" "}
                    <img src="/assets/icons/tick.svg" />
                    Wifi
                  </div>
                  <div>
                    {" "}
                    <img src="/assets/icons/tick.svg" />
                    Central air conditioning
                  </div> */}
                </div>
                {/* <div className="events_amenties_two">
                  <div>
                    {" "}
                    <img src="/assets/icons/tick.svg"></img>Security cameras on
                    property
                  </div>
                  <div>
                    {" "}
                    <img src="/assets/icons/tick.svg"></img>Drinking Water
                  </div>
                  <div>
                    {" "}
                    <img src="/assets/icons/tick.svg"></img>Refrigerator
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>

       {(localStorage.getItem("status")==="active" && bookingData.is_member_can_booking)||(localStorage.getItem("status")!=="active" && bookingData.is_non_member_can_booking) ? <div className="EventsFacilityBooking_card_two">
          <div className="EventsFacilityBooking_card_two_head">
            <div>{bookingData.name}</div>
            <div>
              <span>
                S$
                {storedToken
                   ? bookingData.fee_for_member?.toFixed(2)
                   : bookingData.fee_for_non_member?.toFixed(2)}
              </span>
              {/* /Hrs */}
            </div>
          </div>
          <div className="EventsFacilityBooking_card_two_input d-flex flex-column align-items-center justify-content-center">
            {/* <div className="Card_two_input"> */}
            <div className="card_two_input_one w-100">
              <div>
                {" "}
                <label>Date</label>{" "}
              </div>
              <DatePicker
                style={{ width: "100%" }}
                onChange={handleDateChange}
                disabledDate={disabledDate}
              />
            </div>
            {/* </div> */}
            {/* <div className="Card_two_input"> */}
            {/* <div className="card_two_input_one w-100">
              <div>
                {" "}
                <label>Time</label>
              </div>
              <TimePicker.RangePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    onChange={handleTimeChange}
                    value={[startTime, endTime]}
                    disabledTime={disabledTime}
                  />
             
            </div> */}
            {/* </div> */}
                  
<div class="flex-container"  style={{width:"100%"}}>
                 {allSlots.length>0 && allSlots.map((item,index)=>{
                      return <div  onClick={() => handleButtonClick(item,index)} className={`time-slots-container ${selectedButton === index ? 'time-slots-container-for-active' :'time-slots-container-for-deactive'}  ${!item.is_available && "diasbledCursor"}`}>
                     {moment(item.start,"HH:mm:ss").format("hh:mm a")} - {moment(item.end,"HH:mm:ss").format("hh:mm a")}
                      </div>
                 })}
                 
</div>
            {/* <div className="card_two_input_one w-100">
              <div>
                <div>
                  <label>Adult</label>
                </div>
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  onChange={(value) => setAdult(value)}
                />
              </div>
              <div>
                <div>
                  <label>Children</label>
                </div>
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  onChange={(value) => setChild(value)}
                />
              </div>
            </div> */}
            {/* <div className="card_two_input_one card_input_margin w-100">
              <div>
                <label>GUESTS</label>
              </div>
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                onChange={(value) => setGuest(value)}
              />
            </div> */}
          </div>
          <div className="timing_flex_main">
            <h4 className="text-center d-flex align-items-center justify-content-center">
              <img
                className="mr-2"
                src="/assets/images/peopleBooked.png"
                alt="..."
              />
              {total === null ? 0 : total} People already booked for the day.
            </h4>
            <div className="timing_flex">
              <div className="left">
                $
                {storedToken
                  ? bookingData.fee_for_member
                  : bookingData.fee_for_non_member}{" "}
                x {selectedHours} Hrs
              </div>
              <div className="right">
                S$
                {storedToken
                  ? bookingData.fee_for_member * selectedHours
                  : bookingData.fee_for_non_member * selectedHours}
              </div>
            </div>
            {/* <div className="timing_flex">
              <div className="left">Discount</div>
              <div className="right">-S$0</div>
            </div> */}
            <div className="timing_flex">
              <div className="left"> GST</div>
              <div className="right">S$0</div>
            </div>
          </div>
          <div className="timing_flex">
            <div className="right">Total</div>
            <div className="right">
              S$
              {storedToken
                ? bookingData.fee_for_member * selectedHours
                : bookingData.fee_for_non_member * selectedHours}
            </div>
          </div>
          <div
            onClick={() => {
              if(!bookButton){
                message.error("Booking is not available for selected date")
                return;
              }
              if (
                !startdate ||
                !enddate ||
               selectedButton===null
                // !adult ||
                // !child
              ) {
                message.error("Please select time slot for booking !");
                return;
              }
              
              setVisibleForGuestSelection(true)
            }}
          >
            <button style={{
              cursor:bookButton? "pointer":"not-allowed",
              color:"#fff"
            }}  className="facility_booking_btn">Book Now</button>
          </div>
        </div>:<div></div>}
      </div>
      <div className="container facility_booking_map">
        {/* <img style={{ width: "100%" }} src="./images/Map.png" /> */}
        <div style={{ width: "100%" }}>
          <iframe
            title="Google Map"
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=69%20Balestier%20Road,%20Singapore+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.maps.ie/distance-area-calculator.html">
              measure acres/hectares on map
            </a>
          </iframe>
        </div>
      </div>

      <Modal
    
        title="Fill Form"
        visible={visibleForm}
        onCancel={()=>setVisibleForm(false)}
        width={1000}
        footer={null}
      >
        <Form form={form} onFinish={onFinish}>
          <div style={{display:"flex",gap:"100px"}}>
         <div style={{width:"100%"}}>   <label>Name*</label>
          <Form.Item
            
            name="name"
            onKeyPress={(e) => {
              const charCode = e.which || e.keyCode;
              if (charCode >= 48 && charCode <= 57) {
                e.preventDefault();
              }
            }}
            rules={[{ required: true, message: 'Please enter name' }]}
          >
            <Input placeholder="Name"/>
          </Form.Item>
          </div>
          <div style={{width:"100%"}}>   <label>Email Id*</label>
          <Form.Item
            
            name="email"
            rules={[{ required: true, message: 'Please enter Email Id' }]}
          >
            <Input placeholder="Email"/>
          </Form.Item>
          </div>
        </div>
        <div style={{display:"flex",gap:"100px"}}>
         <div style={{width:"100%"}}>   <label>Phone Number*</label>
          <Form.Item
            
            name="phone"
            rules={[{ required: true, message: 'Please enter Phone Number' }]}
          >
              <Input
              onKeyPress={(e) => {
                const charCode = e.which || e.keyCode;
                if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                  e.preventDefault();
                }
              }}
              placeholder="Phone Number"
              addonBefore={selectBefore}
             
            />
          </Form.Item>
          </div>
          <div style={{width:"100%"}}>   <label>Age*</label>
          <Form.Item
            
            name="age"
            rules={[{ required: true, message: 'Please enter Age' }]}
          >
            <InputNumber style={{width:"100%"}} 
            onChange={(e)=>{
              console.log(e);
              e<18 ? setTypeForGuest("children"):setTypeForGuest("guest");
              // form.setFieldValue("type",)
            }}
            onKeyPress={(e) => {
              const charCode = e.which || e.keyCode;
              if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                e.preventDefault();
              }
            }}
            placeholder="Age" min={0}/>
          </Form.Item>
          </div>
        </div>
        <div style={{display:"flex",gap:"100px"}}>
         <div style={{width:"100%"}}>   <label>Type*</label>
          <Form.Item
            
            // name="type"
            rules={[{ required: true, message: 'Please enter Type' }]}
          >
            <Select placeholder="Select Type" value={typeForGuest}  onChange={handleTypeForGuestChange}>
              {/* <Select.Option value="Adult">Adult</Select.Option> */}
              <Select.Option value="guest">Guest</Select.Option>
              <Select.Option value="children">Children</Select.Option>
              <Select.Option value="spouser">Spouse</Select.Option>
            </Select>
          </Form.Item>
          </div>
          <div style={{width:"100%"}}>   <label>IC Number*</label>
          <Form.Item
            
            name="IC"
            rules={[{ required: true, message: 'Please enter IC' }]}
          >
            <InputNumber style={{width:"100%"}} placeholder="IC" min={0}/>
          </Form.Item>
          </div>
        </div>
          <Form.Item>
           
          <div style={{display:"flex",justifyContent:"flex-end",marginTop:"20px"}}>
         
          <Button htmlType="submit" style={{background: "#F31237",color:"#fff",width:"121px"}}>Save</Button>
          
        </div>
        
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        // title="Fill Form"
        visible={visibleForGuestSelection}
        onCancel={hideModalForGuestSelection}
        // width={1000}
        footer={null}
        // closeIcon={<CloseOutlined style={{top:"-20px",position:"relative",border:"none"}} />}
        
      >
        <Form style={{marginTop:"20px"}} id="myForm"  onFinish={submitForm}> 
       <div style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
        <div>
        <img src="./images/Group.png"/>
        </div>
        <div style={{color: "#12101C",fontSize:"24px",fontWeight:"800",fontStyle:"800"}}>Guest Selection</div>
        <Form.Item style={{textAlign:"center"}} name="isGuest" rules={[{ required: true, message: 'Please Select Field' }]}>
        <Radio.Group >
            <Radio value="notGuest">No Guest</Radio>
            <Radio value="Guest">With Guest</Radio>
        </Radio.Group>
        </Form.Item>
        <Form.Item>
       
        
         <Button style={{background: "#F31237",color:"#fff",width:"121px"}} htmlType="submit">Next</Button>
      </Form.Item>
       </div>
       </Form>
      </Modal>

      <Modal
        centered
        title="Review Guest List"
        visible={visible}
        onCancel={()=>setVisible(false)}
        // width={1000}
        footer={null}
        // closeIcon={<CloseOutlined style={{top:"-20px",position:"relative",border:"none"}} />}
        
      >
        <div>
        <Table key={id}  scroll={{ x:600,y:300 }} pagination={false} rowSelection={rowSelection} columns={columns} dataSource={data} />
        <div style={{display:"flex",justifyContent:"flex-end",marginTop:"20px",gap:"20px"}}>
          <div>
            <Button onClick={()=>{
              setVisibleForm(true)
              form.resetFields();
              setTableId(null)
            }} style={{background: "#fff",width:"121px"}}>Add More</Button>
           
          </div>
          <div>
          <Button onClick={redirectiontoBooking} style={{background: "#F31237",color:"#fff",width:"121px"}}>Book</Button>
          </div>
        </div>
        </div>
      </Modal>
    </div>
  );
}

export default EventsFacilityBooking;

const data = [];
for (let i = 0; i < 1; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
  });
}


