import React from 'react'
import "./membershipFormSubmission.css"


function MembershipFormSubmission() {
  return (
    <div className='Review_confirm'> 
            <div className='tick_icon'>
                <img src="/images/Icon.png"/>
            </div>
            <div className='review_msg_1'>
            Thank you for showing interest in SGIA Membership. Our board will review your membership request and update you via call/email upon confirmation of your membership.
            </div>
            <div className='review_msg_2'>
            You would be given period of 30 days to complete payments.
            </div>
        </div>
  )
}

export default MembershipFormSubmission