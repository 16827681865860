import React,{useEffect} from 'react'
import AboutUs from '../aboutUs/aboutUs';
import HeroSection from '../heroSection/heroSection';
import UpcomingEvents from '../upcomingEvents/upcomingEvents';
import OurTeam from '../ourTeam/ourTeam';
import BeAMemberSmallComponent from '../BeAMemberSmallComponent/BeAMemberSmallComponent';

function Home({setOgData}) {
  useEffect(() => {
    setOgData({
      ogTitle:"SGIA",
    ogDesc:"The Singapore Indian Association was established in 1923 with the objective of promoting the social, physical, intellectual, cultural and the general welfare of its members.",
    ogImage:"https://mysiabucket123.s3.ap-southeast-1.amazonaws.com/sia/new_member/images/08rHLpKrs00ZVHijCY_2D.png"
    })
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
    });
  }, [])
  return (
    <>
      <HeroSection />
      <AboutUs />
      <UpcomingEvents />
      <OurTeam />
    </>
  )
}

export default Home