import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCu7IZXm1ad4BJvvKhYMlqWtc5YLGYFvyg",
  authDomain: "siaa-f948d.firebaseapp.com",
  projectId: "siaa-f948d",
  storageBucket: "siaa-f948d.appspot.com",
  messagingSenderId: "385715257553",
  appId: "1:477285311617:web:6ca702b87cee8523709980",
  measurementId: "G-48ZVC0J4WH"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
