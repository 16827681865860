import React, { useState } from "react";
import { Image, Tag } from "antd";
import { Col, Row } from "antd";
import { useEffect } from "react";
import "./AllEventsFacilityBookng.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from 'moment'
function EventsFacilityBooking() {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate()

  useEffect(()=>{
    if(localStorage.getItem("status")!=="active"){
      navigate("/");
    }
  },[])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/get-facilities-types?facility_id=5`
      )
      .then((res) => {
        // setEvents(res.data.result);
        console.log(res.data.result);
        let filteredEvents = res.data.result.filter((event) => event.status !== "closed");
         setEvents(filteredEvents);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <div className="marginTopFromNavbar">
      {/* <div className="events_component_1">
        <div className="events_1">
          <h1>EVENTS FACILITY</h1>
        </div>
        <div className="events_2">Home - Bookings - Events Facility</div>
      </div> */}
      <div className="header_new">
        <div className="text-container mb-0">
          <div className="text">Presidential Lounge</div>
          {/* <div className="text2">Home - Bookings - Events Facility</div> */}
        </div>
      </div>
      <div className="intro-container">
        <div className="intro-text-header pt-0">A space to work,meet & fun</div>

        <div className="separator"></div>

        <div className="intro-text">
          {/* Events facility booking involves reserving a space for a particular
          event or gathering, such as a conference, wedding, or concert. The
          process typically includes selecting a date, time, and location, as
          well as negotiating pricing and any additional services or amenities
          needed for the event. It is important to book an events facility well
          in advance to ensure availability, and to read and understand the
          terms and conditions of the booking agreement. */}
          Presidential Lounge descriptions
        </div>
      </div>

      {/* <div className="events-card-holder"> */}
      {/* <Row>
          <Col className="card-col" lg={12} sm={24}>
            <div className="events-card">
              <div className="img-holder">
                <Image src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
              </div>
              <div className="content-holder">
                <div className="card-header">Indoor Hall</div>
                <div className="card-sub-header">CELEBRATE WITH FRIENDS WITH FAMILY</div>

                <div className="detail-holder">
 
                <Row style={{paddingBottom:"15px"}}>
                  <Col span={12}>
                    <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />
                        </div>
                        <div className="detail-label">Business Hrs</div>
                      </div>
                      <div className="detail-text">Mon-Fri 10 am - 8 pm</div>
                      <div className="detail-text">Sat-Sun Closed</div>
                    </div>
                  </Col>
                  <Col span={12}>
                  <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
                        <div className="detail-label">Fees</div>
                      </div>
                      <div className="detail-text">$0 for Members</div>
                      <div className="detail-text">$50/pax for non-member</div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />

                        </div>
                        <div className="detail-label">Booking Hrs</div>
                      </div>
                      <div className="detail-text">4 Hrs/Booking</div>
                    </div>
                  </Col>
                  <Col span={12}>
                  
                  </Col>
                </Row>
              
                </div>
                <div style={{padding:"25px"}}>
                  <Link to="/EventsFacilityBooking">
                  <button className='facility_booking_btn'>Book Now</button>
                  </Link>
                </div>
              </div>
             
            </div>
          </Col>

          <Col className="card-col" lg={12} sm={24}>
            <div className="events-card">
              <div className="img-holder">
                <Image src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
              </div>
              <div className="content-holder">
                <div className="card-header">Indoor Hall</div>
                <div className="card-sub-header">CELEBRATE WITH FRIENDS WITH FAMILY</div>

                <div className="detail-holder">
 
                <Row style={{paddingBottom:"15px"}}>
                  <Col span={12}>
                    <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />
                        </div>
                        <div className="detail-label">Business Hrs</div>
                      </div>
                      <div className="detail-text">Mon-Fri 10 am - 8 pm</div>
                      <div className="detail-text">Sat-Sun Closed</div>
                    </div>
                  </Col>
                  <Col span={12}>
                  <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
                        <div className="detail-label">Fees</div>
                      </div>
                      <div className="detail-text">$0 for Members</div>
                      <div className="detail-text">$50/pax for non-member</div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />

                        </div>
                        <div className="detail-label">Booking Hrs</div>
                      </div>
                      <div className="detail-text">4 Hrs/Booking</div>
                    </div>
                  </Col>
                  <Col span={12}>
                  
                  </Col>
                </Row>
              
                </div>
                <div style={{padding:"25px"}}>
                  <Link to="/EventsFacilityBooking">
                  <button className='facility_booking_btn'>Book Now</button>
                  </Link>
                </div>
              </div>
             
            </div>
          </Col>
          
        </Row> */}

      <div className="w-75 m-auto">
        <h2 className="mb-0 text-danger">Recommended ({events.length}) </h2>
      </div>

      <div className="FacilityEvent_main_cust">
        {events.map((events, index) => (
          <div className="FacilityEvent">
            <img
              style={{ height: "320px", objectFit: "cover" }}
              src={events.thumbnail}
            />
            <div style={{ padding: "24px" }}>
              <div className="FacilityEvent_name d-flex justify-content-between">
                <span className="resTextBookingName">{events.name}</span>
                <span className="d-flex justify-content-end align-items-start">
                  {/* <Tag color="green">10 Seats Available</Tag> */}
                </span>
              </div>
              <div className="FacilityEvent_desc">{events.descriptions}</div>
              <div className="FacilityEvent_info">
                <div className="">
                  <div style={{ display: "flex" }}>
                    <div
                      style={{ marginRight: "5px" }}
                      className="detail-icon-business-hrs"
                    >
                      <img
                        src="/assets/icons/briefcase.svg"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </div>
                    <div className="FacilityEvent_timings">
                      <div>Business Hrs</div>
                      <div class="events-info-timing">{events.business_days} {events.buiness_time}</div>
                      <div class="events-info-timing">{events.closed} Closed</div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{ marginRight: "5px" }}
                      className="detail-icon-business-hrs"
                    >
                      <img
                        src="/assets/icons/briefcase.svg"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </div>
                    <div className="FaciltyEvent_booking_hrs">
                      <div>Booking Hrs</div>
                      <div class="events-info-timing">{moment.duration(events.minimum_hours).asHours()} Hrs/Booking</div>
                    </div>
                  </div>
                </div>
                <div className="" style={{display:"flex",justifyContent:"space-between",flexDirection:"column"}}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{ marginRight: "5px" }}
                      className="detail-icon-business-hrs"
                    >
                      <img
                        src="/assets/icons/fee-icon.svg"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </div>
                    <div className="FacilityEvent_fees">
                      <div>FEES</div>
                      <div class="events-info-timing">
                      S${events.fee_for_member}.00 for members
                      </div>
                      <div class="events-info-timing">
                        {/* {events.fee_for_non_member}$ for non members */}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div
                      style={{ marginRight: "5px" }}
                      className="detail-icon-business-hrs"
                    >
                      <img
                        src="/assets/icons/pax.svg"
                        style={{ width: "16px" }}
                        alt=""
                      />
                    </div>
                    <div className="FaciltyEvent_booking_hrs">
                      <div>PAX</div>
                      <div class="events-info-timing">{!events.pax_total?"0":events.pax_total} PAX Booking Done</div>
                    </div>
                  </div>
                </div>
              </div>

              <Link
                to={`/presidentialLoungeFacilityBooking?id=${events.id}&type=facility_type`}
              >
                <button className="facility_booking_btn">Book Now</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EventsFacilityBooking;
{
  /* <div className="FacilityEvent">
          <img
            style={{ height: "320px", objectFit: "cover" }}
            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
          />
          <div style={{ padding: "24px" }}>
            <div className="FacilityEvent_name">Indoor Hall</div>
            <div className="FacilityEvent_desc">
              Celebrate With Friends And Family
            </div>
            <div className="FacilityEvent_info">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div
                  style={{ marginRight: "5px" }}
                  className="detail-icon-business-hrs"
                >
                  <img
                    src="/assets/icons/briefcase.svg"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </div>
                <div className="FacilityEvent_timings">
                  <div className="card_details_text">Business Hrs</div>
                  <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                  <div class="events-info-timing">Sat-Sun Closed</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div
                  style={{ marginRight: "5px" }}
                  className="detail-icon-business-hrs"
                >
                  <img
                    src="/assets/icons/fee-icon.svg"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </div>
                <div className="FacilityEvent_fees">
                  <div>FEES</div>
                  <div class="events-info-timing">0$ for members</div>
                  <div class="events-info-timing">50$/tax for non members</div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
              }}
            >
              <div
                style={{ marginRight: "5px" }}
                className="detail-icon-business-hrs"
              >
                <img
                  src="/assets/icons/fee-icon.svg"
                  style={{ width: "16px" }}
                  alt=""
                />
              </div>
              <div className="FaciltyEvent_booking_hrs">
                <div>Booking Hrs</div>
                <div class="events-info-timing">4 Hrs/Booking</div>
              </div>
            </div>

            <Link to="/EventsFacilityBooking">
              <button className="facility_booking_btn">Book Now</button>
            </Link>
          </div>
        </div>
        <div className="FacilityEvent">
          <img
            style={{ height: "320px", objectFit: "cover" }}
            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
          />
          <div style={{ padding: "24px" }}>
            <div className="FacilityEvent_name">Indoor Hall</div>
            <div className="FacilityEvent_desc">
              Celebrate With Friends And Family
            </div>
            <div className="FacilityEvent_info">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div
                  style={{ marginRight: "5px" }}
                  className="detail-icon-business-hrs"
                >
                  <img
                    src="/assets/icons/briefcase.svg"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </div>
                <div className="FacilityEvent_timings">
                  <div className="card_details_text">Business Hrs</div>
                  <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                  <div class="events-info-timing">Sat-Sun Closed</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div
                  style={{ marginRight: "5px" }}
                  className="detail-icon-business-hrs"
                >
                  <img
                    src="/assets/icons/fee-icon.svg"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </div>
                <div className="FacilityEvent_fees">
                  <div>FEES</div>
                  <div class="events-info-timing">0$ for members</div>
                  <div class="events-info-timing">50$/tax for non members</div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
              }}
            >
              <div
                style={{ marginRight: "5px" }}
                className="detail-icon-business-hrs"
              >
                <img
                  src="/assets/icons/fee-icon.svg"
                  style={{ width: "16px" }}
                  alt=""
                />
              </div>
              <div className="FaciltyEvent_booking_hrs">
                <div>Booking Hrs</div>
                <div class="events-info-timing">4 Hrs/Booking</div>
              </div>
            </div>

            <Link to="/EventsFacilityBooking">
              <button className="facility_booking_btn">Book Now</button>
            </Link>
          </div>
        </div>
        <div className="FacilityEvent">
          <img
            style={{ height: "320px", objectFit: "cover" }}
            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
          />
          <div style={{ padding: "24px" }}>
            <div className="FacilityEvent_name">Indoor Hall</div>
            <div className="FacilityEvent_desc">
              Celebrate With Friends And Family
            </div>
            <div className="FacilityEvent_info">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div
                  style={{ marginRight: "5px" }}
                  className="detail-icon-business-hrs"
                >
                  <img
                    src="/assets/icons/briefcase.svg"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </div>
                <div className="FacilityEvent_timings">
                  <div className="card_details_text">Business Hrs</div>
                  <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                  <div class="events-info-timing">Sat-Sun Closed</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <div
                  style={{ marginRight: "5px" }}
                  className="detail-icon-business-hrs"
                >
                  <img
                    src="/assets/icons/fee-icon.svg"
                    style={{ width: "16px" }}
                    alt=""
                  />
                </div>
                <div className="FacilityEvent_fees">
                  <div>FEES</div>
                  <div class="events-info-timing">0$ for members</div>
                  <div class="events-info-timing">50$/tax for non members</div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
              }}
            >
              <div
                style={{ marginRight: "5px" }}
                className="detail-icon-business-hrs"
              >
                <img
                  src="/assets/icons/fee-icon.svg"
                  style={{ width: "16px" }}
                  alt=""
                />
              </div>
              <div className="FaciltyEvent_booking_hrs">
                <div>Booking Hrs</div>
                <div class="events-info-timing">4 Hrs/Booking</div>
              </div>
            </div>

            <Link to="/EventsFacilityBooking">
              <button className="facility_booking_btn">Book Now</button>
            </Link>
          </div>
        </div> */
}
