import React from 'react'
import styles from "./RedDot.module.css"
import RedDotLogo from "../../Assets/RedDot/Red dot logo.png"
import { Button } from 'antd'
import { Link } from 'react-router-dom'

function RedDotBanner() {
  return (
    <>
      <a target="_blank" href="https://reddotinnovative.com">

        <div className={styles.background}>
          <div className={styles.powered}>Powered By</div>
          <div className={styles.RedDotLogo}><img src={RedDotLogo} /></div>
          <div className={styles.moto}>Innovate |  Automate | Accelerate</div>
          {/* <div className={styles.buttonContainer}>
            <Button><a target="_blank" href="https://reddotinnovative.com">Contact Us</a></Button>
        </div> */}
    </div >
      </a>
    </>
  )
}

export default RedDotBanner