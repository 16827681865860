import { message } from "antd";
import axios from "axios";

const paymentUrl = async (id, userId,type, total_amount, bookingName, history_id,club_history_id,tax,service_charges) => {
  console.log(id, type, total_amount, bookingName,userId);
  let successUrl = "";
  if(type==="facility_type"){
    type="facility"
  }

 if(type==="sport"){
    successUrl = `${process.env.REACT_APP_HOST_URL}/SportsSection/join-us?id=${history_id}&status=success`
  }
  else if(type==="membership"){
    successUrl= `${process.env.REACT_APP_HOST_URL}/membershipBookingConfirmed`
  }
  else{
  
      successUrl= `${process.env.REACT_APP_HOST_URL}/EventsFacilityBookingConfirmed?id=${id}&type=${type}&total_amount=${(parseFloat(total_amount) + parseFloat(tax) + parseFloat(service_charges))?.toFixed(2)}`
    
  }

  let data = {
    amount: (parseFloat(total_amount) + parseFloat(tax) + parseFloat(service_charges))?.toFixed(2),
    single_amount: total_amount,
    tax: tax,
    service_charge: service_charges,
    successUrl: successUrl,
    cancelUrl: `${process.env.REACT_APP_HOST_URL}/failpayment`,
    booking_id: (type !== "membership" && type!=="sport") ? id : null,
    membership_id: type === "membership" ? id : null,
    membership_history_id: type==="membership"?history_id:null,
    type: type,
    sports_club_id: type==="sport"?id:null,
    club_history_id: type==="sport"?club_history_id:null,
    user_id: userId,
  };
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/get-checkout-payment-link `,
    headers: {
      "Content-Type": "application/json",
    },

    data: data,
  };
  try {
    const res = await axios.request(config);
    console.log(res.data);
    localStorage.setItem("payment_id", res.data.paymentId);
    localStorage.setItem("bookingName", bookingName);
    // &bookingName=${bookingName}
    return res.data;
  } catch (err) {
    return false;
    console.error(err.response.data.message);
    message.error(err.response.data.message||"Error in payment");
    // message.error("Error in payment");
  }
};

export { paymentUrl };
