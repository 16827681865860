import React, { useState, useRef, useEffect } from "react";
import styles from "./Login.module.css";
import OtpInput from "otp-input-react";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../firebase.config";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Select,
} from "antd";
import { signInWithPhoneNumber } from "firebase/auth";
// import { ReCAPTCHA } from "react-google-recaptcha";
import country_codes from "../MembershipForm/countryCodes";
import axios from "axios";
import Email from "./Email";

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
function Login() {
  const navigate = useNavigate();
  const [otpToggle, setOtpToggle] = useState(false);
  const [isUserExist, setIsUserExist] = useState(false);
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [countryCode, setCountryCode] = useState("+65");
  const inputs = useRef([]);
  const captchaRef = useRef(null);
  const { Option } = Select;
  const handleKeyUp = (index, e) => {
    const input = inputs.current[index];
    const nextInput = inputs.current[index + 1];

    if (e.target.value && nextInput) {
      nextInput.focus();
      nextInput.input.select();
    }

    if (!e.target.value && index > 0) {
      const prevInput = inputs.current[index - 1];
      prevInput.focus();
      prevInput.input.select();
    }
  };
  const onFinish = (values) => {
    console.log("Success:", values);
    setOtpToggle(true);
  };
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignUp();
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        },
        auth
      );
    }
  }
  function onSignUp() {
    console.log(`${countryCode}${ph}`);
    if (ph) {
      let data = { phone_number: `${ph}` };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/check-member`, data)
        .then((res) => {
          setIsUserExist(res.data.success);

          if (res.data.success === true) {
            onCaptchVerify();
            const appVerifier = window.recaptchaVerifier;
            const formatPh = `${countryCode}${ph}`;
            // const formatPh = `+917089089254`;

            signInWithPhoneNumber(auth, formatPh, appVerifier)
              .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                console.log("confirmationResult", confirmationResult);
                setOtpToggle(true);
                console.log('test');
              })
              .catch((error) => {
                setOtpToggle(false);
                console.log("error", error);
                if (error) {
                  message.error("Invalid number entered ! please confirm country code and registered number")
                }
              });
          } else {
            message.error("Invalid Mobile Number, Please Enter Registered Mobile Number !");
          }
          console.log(res.data.success);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (isUserExist) {

    // }
  }
  function onOTPVerify() {
    if (otp.length!==6) {
      message.error('Please Enter Valid OTP !')
      return
    }
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        if (res.user.uid) {
          let logData = {
            phone: ph,
            uid: res.user.uid,
          };
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/member-login`, logData)
            .then((res) => {
              console.log("login user", res);
              localStorage.setItem("token", res.data.token.token);
              navigate("/MemberProfilePage");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
    });
  }, []);
  return (
    <div className={`${styles.Login_main} marginTopFromNavbar`}>
      <div id="recaptcha-container"></div>
      {/* <ReCAPTCHA
        sitekey="6Lf4tE8mAAAAALE6_aBgB17cFNc4scM8JVOAYTgD"
        ref={captchaRef}
      /> */}
      {/* <div className='events_component_1'>
        <div className="events_1">
          <h1>Member LOG IN</h1>
        </div>
        <div className='events_2'>
          Home - Member Log In
        </div>
      </div> */}

      {/* <div className="header_new">
          <div className="text-container">
            <div className="text">Member LOG IN</div>
            <div className="text2">Home - Member Log In</div>
          </div>
      </div> */}
      {/* <div className='Login_component_2'>
        Community organizing is all about building grassroots support. It's about identifying the people around you with whom you can create a common, passionate cause. And it's about ignoring the conventional wisdom of company politics and instead playing the game by very different rules.
      </div> */}

      <div className={`${styles.login_component_1} margin-bottom-extra`} >
        <div className="m-auto d-flex flex-wrap justify-content-center">
          <div className={styles.login_information}>
            <div className={`${styles.login_info_head} d-flex align-items-center justify-content-center`}>
              <MemberLoginIcon />{" "}
              <span className="ml-2" style={{ color: "#F31237" }}>
                {" "}
                Member Login{" "}
              </span>
            </div>
            {/* <form>
            <div className='id'>Member Id</div>
            <input type="text" className='login-input' placeholder='Enter your member id' />
            <div className='pass' >Password</div>
            <input type="password" className='login-input' placeholder='Enter your password' name="" id="" />
            <div className='log_in_btn'>
              <Link to = "/MemberProfilePage">
                <button className='log_in_btn'>Log in now</button>
              </Link>
            </div>
            <div className='forgot'>
              <a href="#forget">Forgot Password</a>
            </div>
          </form> */}

            {/* {otpToggle ? (
              <Form
                name="basic"
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <p className="mb-5">
                  Please enter OTP sent to your registered mobile number.
                </p>
                <h4 className="font-weight-bold my-4">OTP Verification</h4>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  otpType="number"
                  disabled={false}
                  autofocus={true}
                  OTPLength={6}
                ></OtpInput>
                <p className="text-center mb-5 mt-5">
                  Don’t receive OTP?
                  <Link to={"/login"} onClick={() =>{
                    setOtp('')
                     setOtpToggle(false)
                     }}>
                    Request Again
                  </Link>
                </p>
                <Form.Item>
                  <Button
                    className="log_in_btn mt-5"
                    type="danger"
                    onClick={onOTPVerify}
                    disabled={otp.length!==6}
                  >
                    Log In Now
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Form
                name="basic"
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <h4 className="mb-3">Please Enter Your Registered Number !</h4>
                <Form.Item name="phone_number">
                  <Input
                    addonBefore={
                      <>
                        <Select
                          defaultValue={countryCode}
                          style={{
                            width: "80px",
                          }}
                          onChange={(e) => setCountryCode(e)}
                        >
                          {
                            country_codes.map((elem,i)=>{
                              return <><Option key={i} value={elem.code}>{elem.code}</Option></>
                            })
                          }
                        </Select>
                      </>
                    }
                    onChange={(e) => setPh(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    className="log_in_btn"
                    type="danger"
                    onClick={() => {
                      onSignUp();
                    }}
                  >
                    Send OTP
                  </Button>
                </Form.Item>
              </Form>
            )} */}
            <Email/>
          </div>
          <div className={styles.signup}>
            <div className={styles.signup_head}>Not a Member Yet?</div>
            <div className={styles.signup_component}>
              Sign up now & get exclusive access to all the content from
              singapore indian association.
            </div>
            <div className={styles.be_a_member_icon}>
              <div className={styles.member_icon}></div>
            </div>

            <Link to="/Membership">
              <div className={styles.signup_btn}>
                <button className={styles.signup_in_btn}>Be a Member</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

const PrefixComp = ({ text }) => {
  return (
    <div
      className="font-weight-bold"
      style={{ fontSize: "12px", width: "86px", textAlign: "left" }}
    >
      {text}
    </div>
  );
};
const MemberLoginIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_967_1330)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 6C13.93 6 15.5 7.57 15.5 9.5C15.5 11.43 13.93 13 12 13C10.07 13 8.5 11.43 8.5 9.5C8.5 7.57 10.07 6 12 6ZM12 20C9.97 20 7.57 19.18 5.86 17.12C7.55 15.8 9.68 15 12 15C14.32 15 16.45 15.8 18.14 17.12C16.43 19.18 14.03 20 12 20Z"
        fill="#F31237"
      />
    </g>
    <defs>
      <clipPath id="clip0_967_1330">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
