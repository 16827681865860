import React from "react";
import styles from "./BeAMemberSmallComponent.module.css";
import { Button } from "antd";
import { Link, useLocation } from "react-router-dom";

const Include = [
  "/AllEventsFacilityBooking",
  "/",
  "/management-committee",
]

const BeAMemberSmallComponent = () => {
  const storedToken = localStorage.getItem("token");
  const location = useLocation();
  return (
    <>
    {
      !storedToken && (Include.includes(location.pathname)) &&
    <div className={styles.beAmemberParentBox}>
      <div className={styles.first_box} >
        <h2 className={styles.h2}>We'd love to have you!</h2>
        <p>
        Join The Singapore Indian Association today & unlock the benefits of membership.
        </p>
      </div>
      <div className={styles.second_box}>
        <Link to={"/Membership"}>
          <button className={styles.button}>Be a Member</button>
        </Link>
      </div>
    </div>
    }
    </>
  );
};

export default BeAMemberSmallComponent;
