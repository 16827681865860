const country_codes = [
    {"country": "Singapore", "code": "+65"},
    {"country": "India", "code": "+91"},
    {"country": "Malaysia", "code": "+60"},
    {"country": "Afghanistan", "code": "+93"},
    {"country": "Albania", "code": "+355"},
    {"country": "Algeria", "code": "+213"},
    {"country": "Argentina", "code": "+54"},
    {"country": "Australia", "code": "+61"},
    {"country": "Austria", "code": "+43"},
    {"country": "Bangladesh", "code": "+880"},
    {"country": "Belgium", "code": "+32"},
    {"country": "Bolivia", "code": "+591"},
    {"country": "Botswana", "code": "+267"},
    {"country": "Brazil", "code": "+55"},
    {"country": "Bulgaria", "code": "+359"},
    {"country": "Cambodia", "code": "+855"},
    {"country": "Cameroon", "code": "+237"},
    {"country": "Canada", "code": "+1"},
    {"country": "Chile", "code": "+56"},
    {"country": "China", "code": "+86"},
    {"country": "Colombia", "code": "+57"},
    {"country": "Costa Rica", "code": "+506"},
    {"country": "Croatia", "code": "+385"},
    {"country": "Cuba", "code": "+53"},
    {"country": "Czech Republic", "code": "+420"},
    {"country": "Denmark", "code": "+45"},
    {"country": "Dominican Republic", "code": "+1"},
    {"country": "Ecuador", "code": "+593"},
    {"country": "Egypt", "code": "+20"},
    {"country": "El Salvador", "code": "+503"},
    {"country": "England", "code": "+44"},
    {"country": "Estonia", "code": "+372"},
    {"country": "Ethiopia", "code": "+251"},
    {"country": "Fiji", "code": "+679"},
    {"country": "Finland", "code": "+358"},
    {"country": "France", "code": "+33"},
    {"country": "Germany", "code": "+49"},
    {"country": "Ghana", "code": "+233"},
    {"country": "Greece", "code": "+30"},
    {"country": "Guatemala", "code": "+502"},
    {"country": "Haiti", "code": "+509"},
    {"country": "Honduras", "code": "+504"},
    {"country": "Hungary", "code": "+36"},
    {"country": "Iceland", "code": "+354"},
    {"country": "Indonesia", "code": "+62"},
    {"country": "Iran", "code": "+98"},
    {"country": "Iraq", "code": "+964"},
    {"country": "Ireland", "code": "+353"},
    {"country": "Israel", "code": "+972"},
    {"country": "Italy", "code": "+39"},
    {"country": "Jamaica", "code": "+1"},
    {"country": "Japan", "code": "+81"},
    {"country": "Jordan", "code": "+962"},
    {"country": "Kenya", "code": "+254"},
    {"country": "Kuwait", "code": "+965"},
    {"country": "Laos", "code": "+856"},
    {"country": "Latvia", "code": "+371"},
    {"country": "Lebanon", "code": "+961"},
    {"country": "Libya", "code": "+218"},
    {"country": "Lithuania", "code": "+370"},
    {"country": "Madagascar", "code": "+261"},
    {"country": "Mali", "code": "+223"},
    {"country": "Malta", "code": "+356"},
    {"country": "Mexico", "code": "+52"},
    {"country": "Mongolia", "code": "+976"},
    {"country": "Morocco", "code": "+212"},
    {"country": "Mozambique", "code": "+258"},
    {"country": "Namibia", "code": "+264"},
    {"country": "Nepal", "code": "+977"},
    {"country": "Netherlands", "code": "+31"},
    {"country": "New Zealand", "code": "+64"},
    {"country": "Nicaragua", "code": "+505"},
    {"country": "Nigeria", "code": "+234"},
    {"country": "Norway", "code": "+47"},
    {"country": "Pakistan", "code": "+92"},
    {"country": "Panama", "code": "+507"},
    {"country": "Paraguay", "code": "+595"},
    {"country": "Peru", "code": "+51"},
    {"country": "Philippines", "code": "+63"},
    {"country": "Poland", "code": "+48"},
    {"country": "Portugal", "code": "+351"},
    {"country": "Romania", "code": "+40"},
    {"country": "Russia", "code": "+7"},
    {"country": "Saudi Arabia", "code": "+966"},
    {"country": "Scotland", "code": "+44"},
    {"country": "Senegal", "code": "+221"},
    {"country": "Serbia", "code": "+381"},
    {"country": "Slovakia", "code": "+421"},
    {"country": "South Africa", "code": "+27"},
    {"country": "South Korea", "code": "+82"},
    {"country": "Spain", "code": "+34"},
    {"country": "Sri Lanka", "code": "+94"},
    {"country": "Sudan", "code": "+249"},
    {"country": "Sweden", "code": "+46"},
    {"country": "Switzerland", "code": "+41"},
    {"country": "Syria", "code": "+963"},
    {"country": "Taiwan", "code": "+886"},
    {"country": "Tajikistan", "code": "+992"},
    {"country": "Thailand", "code": "+66"},
    {"country": "Tonga", "code": "+676"},
    {"country": "Tunisia", "code": "+216"},
    {"country": "Turkey", "code": "+90"},
    {"country": "Ukraine", "code": "+380"},
    {"country": "United Arab Emirates", "code": "+971"},
    {"country": "United Kingdom", "code": "+44"},
    {"country": "United States", "code": "+1"},
    {"country": "Uruguay", "code": "+598"},
    {"country": "Venezuela", "code": "+58"},
    {"country": "Vietnam", "code": "+84"},
    {"country": "Wales", "code": "+44"},
    {"country": "Zambia", "code": "+260"},
    {"country": "Zimbabwe", "code": "+263"},
]
export default country_codes