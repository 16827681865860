import { Button, DatePicker, Divider, Form, Input, Select, message } from "antd";
import React, { useCallback } from "react";
import {
  PlusOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Upload } from "antd";
import { useState, useEffect } from "react";
import "./BasicDetailsForm.css";
import occupation from "./occupationList";
import country from "./country";
import moment from "moment";
import countries from "./countries";
import nationality from "./nationality";
import countryCodes from "./countryCodes";
import axios from "axios";
import ImgCrop from 'antd-img-crop';
import { Link, useLocation } from "react-router-dom";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};



function BasicDetailsForm({
  onInputHandleChange,
  uploadeProps,
  membershipFormType,
  setMembershipFormType,
  formVisible,
  setFormVisible,
  membershipFormData,
  setMembershipFormData,
  setSuccessModal,
  successModal,
}) {
  const { imageUrl, setImageUrl, fileList, setFileList } = uploadeProps;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [amount, setAmount] = useState(0);
  const [id, setId] = useState();
  const [MembershipId, setMembershipId] = useState();
  const location = useLocation();
  const category = location.state.category;
  // const [successModal,setSuccessModal]=useState();

  const [inputData, setInputData] = useState({
    name_to_be_printed_on_card: "",
  });
  // const [fileList, setFileList] = useState([
  //   // {
  //   //     uid: '-1',
  //   //     name: 'image.png',
  //   //     status: 'done',
  //   //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   // }
  // ]);
  const { Option } = Select;
  // const location = useLocation();

  const query = new URLSearchParams(location.search);
  const Id = query.get("id");

  
  const selectBefore = (
    <Select
      defaultValue="+65"
      style={{
        width: "80px",
      }}
      onChange={(e) => onInputHandleChange("country_code", e)}
    >
      {" "}
      {countryCodes.map((elem, i) => {
        return (
          <>
            <Option key={i} value={elem.code}>
              {elem.code}
            </Option>
          </>
        );
      })}
      {/* <Option value="+65">+65</Option>
      <Option value="+91">+91</Option> */}
    </Select>
  );
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const handleImageChange = (info) => {
    console.log(info);
    const file = info.fileList[0]?.originFileObj;
    let formData = new FormData();
    if (file) {
      formData.append("file", file);
      // console.log(formData);
      setFileList(file);
    }
    if (info?.fileList[0]) {
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  }, []);

  // useEffect(()=>{
  //   if(membershipFormType==="NEWMEMBER"){

  //   }
  //   else if(membershipFormType==="RENEWAL"){

  //   }
  // },[membershipFormType])

  const onChangeValue = (event) => {
    setMembershipFormType(event.target.value);
    onInputHandleChange("membership_term", event.target.value);
  };

  console.log(membershipFormData)
  useEffect(() => {
    if (membershipFormType === "renewal") {
      getMembershipData(localStorage.getItem("id"));
    }
  }, [membershipFormType]);
  const getMembershipData = async (value) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/get-membership-form-detail?id=${value}`,
      // headers: {
      //   "content-type": "application/json",
      //    Authorization: localStorage.getItem('token')
      // },
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.status) {
          if (response.data.result) {
            setFormVisible(true);
            setSuccessModal(true);
            console.log("memberData",response.data.result);
            // setMembershipFormData();
            if(Id!=response.data?.result?.membership_id){
              setMembershipFormData(()=>({
                ...response.data.result,
                plan:location?.state?.name,
                membership_id:Id
              }))
            }else{
             setMembershipFormData(response.data.result)
          
            }
            setId(response.data.result.id);
            setMembershipId(response.data.result.membership_id);
          }
        } else {
          setSuccessModal(false);
          setFormVisible(false);
        }
        // return false
      })
      .catch(function (error) {
        console.log(error);
        // return false
      });
  };

  let timeOutId;
  const functionCardAppearence = (e) => {
    // setSuggestion(e.target.value)
    clearTimeout(timeOutId);
    // setSuccessModal(true);
    // setFormVisible(true);
    timeOutId = setTimeout(() => {
      const { value, name } = e.target;
      getMembershipData(value);
      // searchFunction(value)
    }, 1000);
  };
  const onMembershipDurationChange = async (value) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/renew/monthly-price`, {
        user_id: id,
        durations: parseInt(value),
        membership_id: MembershipId
      });

      if (response.status=== 200) {
        
        setAmount(response.data.amount);
        console.log("Amount",amount);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <div
        className={formVisible === false && "marginBottom150px"}
        style={{ marginBottom: "30px" }}
      >
        {/* <div class="membershipTypeFormType">Membership Type</div> */}
        {/* <div style={{display:"flex",alignItems:"center",marginTop:"16px"}}>
              <div>
                <input type="radio" checked={membershipFormType === 'new'}  name="member" value="new"  onChange={onChangeValue} />{" "}
                <label htmlFor="newmember" class="membershipTypeFormTypeRadio">New Member</label>
              </div>
              <div>
                <input type="radio" name="member" checked={membershipFormType === 'renewal'} value="renewal"  onChange={onChangeValue} />{" "}
                <label htmlFor="renewal"  class="membershipTypeFormTypeRadio">Renewal</label>
              </div>
              </div> */}

        {/* {membershipFormType==="renewal" &&<div style={{marginTop:"32px"}}> 
              <div class="membershipTypeFormTypeRadio">Enter Membership ID</div> */}
        {/* <input class="typeFormRadioInput" onChange={functionCardAppearence}/>
              {successModal===true&&<div class="successMembershipId"><CheckCircleOutlined style={{marginRight:"10px"}}/>Membership ID details verified successfully!</div>}
              {successModal===false && <div class="FailMembershipId"><ExclamationCircleOutlined style={{marginRight:"10px"}}/>Invalid Membership ID.</div>} */}
        {/* </div>} */}
      </div>
      {formVisible && (
        <>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item label="Upload Photo" name="pic">
                {/* <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload> */}
            <div>
                <ImgCrop modalClassName="modalCropperImageUpload">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    maxCount={1}
                    onChange={handleImageChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>
                </div>
              </Form.Item>
            </div>
            <div></div>
          </div>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item label="Title" name="title" style={{ width: "100%" }}>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) => onInputHandleChange("title", e)}
                >
                  <Select.Option value="Miss">Miss</Select.Option>
                  <Select.Option value="Ms">Ms</Select.Option>
                  <Select.Option value="Mr">Mr</Select.Option>
                  <Select.Option value="Mrs">Mrs</Select.Option>
                  <Select.Option value="Dr">Dr</Select.Option>
                  <Select.Option value="Mdm">Mdm</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Name as per NRIC/FIN*"
                name="name_as_per_NRIC"
                style={{ width: "100%" }}
              >
                <Input
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    if (charCode >= 48 && charCode <= 57) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    onInputHandleChange("name_as_per_NRIC", e.target.value)
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Name to be printed on card*"
                name="name_to_be_printed_on_card"
                style={{ width: "100%" }}
              >
                <Input
                  value={inputData.name_to_be_printed_on_card}
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    if (charCode >= 48 && charCode <= 57) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    // const filteredValue = value
                    //   .split("")
                    //   .filter((char) => isNaN(char))
                    //   .join("");
                    // setInputData((prevData) => ({
                    //   ...prevData,
                    //   name_to_be_printed_on_card: filteredValue,
                    // }));
                    onInputHandleChange(
                      "name_to_be_printed_on_card",
                      value
                    );
                  }}
                />
              </Form.Item>
            </div>
            <div className="besicFormInnerDiv">
              {/* <Form.Item
            label="Joined Date*"
            name="join_date"
            style={{ width: "100%" }}
          >
            <DatePicker
              onChange={(e) => onInputHandleChange("join_date", e)}
              style={{ width: "100%" }}
            />
          </Form.Item> */}
              <Form.Item
                label="Company Name*"
                name="company_name"
                style={{ width: "100%" }}
              >
                <Input
                  onChange={(e) =>
                    onInputHandleChange("company_name", e.target.value)
                  }
                />
              </Form.Item>
            </div>
            
          </div>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item label="Gender" name="gender" style={{ width: "100%" }}>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) => onInputHandleChange("gender", e)}
                >
                  <Select.Option value="male">Male</Select.Option>
                  <Select.Option value="female">Female</Select.Option>
                  {/* <Select.Option value="others">Others</Select.Option> */}
                </Select>
              </Form.Item>
              {
              membershipFormType === "renewal" &&      <Form.Item
                  label="Membership Type"
                  // name="Amount"
                    style={{ width: "100%" }}>
                    <div style={{
                      display:"flex",
                      gap:"10px",
                      alignItems:"center"
                    }}>
                      <div>{membershipFormData?.plan}</div>
                      <Link to="/renewalMembership" style={{
                        border:"1px solid #000",
                        borderRadius:"10px",
                        padding:"10px",
                        cursor:"pointer"
                      }}><>Change Plan</></Link>
                    </div>
                  </Form.Item>
}
                  
            </div>
            {
              membershipFormType === "renewal" && 
              <div className="besicFormInnerDiv">
              <Form.Item
                label="Membership Duration (Month)"
                name="membership_period_new"
                style={{ width: "100%" }}
              >
                    <Input
                       min={1}
                       type="number"
                       onKeyPress={(e) => {
                        const charCode = e.which || e.keyCode;
                        const value = parseInt(e.target.value + String.fromCharCode(charCode), 10);
                        if (value < 1 || isNaN(value)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (value < 1) {
                          value = 1;
                        }
                        onInputHandleChange("membership_period_new", value);
                        onMembershipDurationChange(value);
                      }}
                  
                />
                  </Form.Item>
                  <Form.Item
                  label="Amount"
                  name="Amount"
                    style={{ width: "100%" }}>
                    <h2>S${amount?.toFixed(2)}</h2>
                  </Form.Item>
            </div>
            }
            
          </div>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Date of Birth*"
                name="date_of_birth"
                style={{ width: "100%" }}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  defaultPickerValue={moment().subtract(category!=="student"?12:7, 'years')}
                  disabledDate={(current) => {
                    // Disable future dates
                    return current && current > moment().subtract(category!=="student"?10:5 , 'years').endOf('day');
                  }}
                  onChange={(e) =>{
                    if(e>moment().subtract(10, 'years')){
                      message.warn("Age should be greater than 10 years")
                    }
                    else{
                      onInputHandleChange("date_of_birth", e)
                    }
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
            
            <div className="besicFormInnerDiv">
              <Form.Item label="Race*" name="race" style={{ width: "100%" }}>
                {/* <Input
              onChange={(e) => onInputHandleChange("race", e.target.value)}
            /> */}
                <Select
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                  onChange={(e) => onInputHandleChange("race", e)}
                >
                  <Select.Option value="Chinese">Chinese</Select.Option>
                  <Select.Option value="Malay">Malay</Select.Option>
                  <Select.Option value="Indian">Indian</Select.Option>
                  <Select.Option value="Myanmarese">Myanmarese</Select.Option>
                  <Select.Option value="Burmese">Burmese</Select.Option>
                  <Select.Option value="Filipino">Filipino</Select.Option>
                  <Select.Option value="Eurasian">Eurasian</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Nationality"
                name="nationality"
                style={{ width: "100%" }}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  onChange={(e) => onInputHandleChange("nationality", e)}
                >
                  {nationality.map((elem, i) => {
                    return (
                      <>
                        <Select.Option key={i} value={elem}>
                          {elem}
                        </Select.Option>
                      </>
                    );
                  })}
                  {/* <Select.Option value="singapore">Singapore</Select.Option>
              <Select.Option value="indian">Indian</Select.Option>
              <Select.Option value="other">Other</Select.Option> */}
                </Select>
              </Form.Item>
            </div>
            <div className="besicFormInnerDiv">
              <Form.Item
                label="NRIC/FIN No*"
                name="NRIC_and_FIN_no"
                style={{ width: "100%" }}
                rules={[{
                  pattern: /^[STFG]\d{7}[A-Z]$/,
                  message: "Please enter valid NRIC/FIN number"
                }]}
              >
                <Input
                  onChange={(e) =>{
                    const regex = /^[STFG]\d{7}[A-Z]$/;
                    const inputValue = e.target.value;
                    if (regex.test(inputValue) || inputValue === "") {
                      onInputHandleChange("NRIC_and_FIN_no", e.target.value);
                    }
                  }
                }
                />
              </Form.Item>
            </div>
          </div>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item label="Email" name="email" style={{ width: "100%" }}>
                <Input
                  onChange={(e) => onInputHandleChange("email", e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Marital Status*"
                name="marital_status"
                style={{ width: "100%" }}
              >
                <Select
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch
                  onChange={(e) => onInputHandleChange("marital_status", e)}
                >
                  <Select.Option value="Single">Single</Select.Option>
                  <Select.Option value="Married">Married</Select.Option>
                  <Select.Option value="Separated">Separated</Select.Option>
                  <Select.Option value="Divorced">Divorced</Select.Option>
                  <Select.Option value="Widowed">Widowed</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Mobile Number*"
                name="mobile_number"
                style={{ width: "100%" }}
              >
                <Input
                  addonBefore={selectBefore}
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    onInputHandleChange("mobile_number", e.target.value)
                  }
                />
              </Form.Item>
            </div>
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Home Contact Number"
                name="home_contact_number"
                style={{ width: "100%" }}
              >
                <Input
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    onInputHandleChange("home_contact_number", e.target.value)
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Office Contact Number"
                name="office_contact_number"
                style={{ width: "100%" }}
              >
                <Input
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    onInputHandleChange("office_contact_number", e.target.value)
                  }
                />
              </Form.Item>
            </div>
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Occupation* "
                name="occupation"
                style={{ width: "100%" }}
              >
                <Select
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                  onChange={(e) => onInputHandleChange("occupation", e)}
                >
                  {occupation.map((elem, i) => {
                    return <Select.Option value={elem}>{elem}</Select.Option>;
                  })}
                  {/* <Select.Option value="demo">Demo</Select.Option> */}
                </Select>
              </Form.Item>
            </div>
          </div>
          {/* <div className="basicFormRow">
        <div className="besicFormInnerDiv">
          <Form.Item
            label="Company Name*"
            name="company_name"
            style={{ width: "100%" }}
          >
            <Input
              onChange={(e) =>
                onInputHandleChange("company_name", e.target.value)
              }
            />
          </Form.Item>
        </div>
        <div></div>
      </div> */}

          <Divider />
          <p className="font-weight-bold">Address</p>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Block No"
                name="block_no"
                style={{ width: "100%" }}
              >
                <Input
                  onChange={(e) =>
                    onInputHandleChange("block_no", e.target.value)
                  }
                />
              </Form.Item>
            </div>
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Street Number and Name"
                name="street_no"
                style={{ width: "100%" }}
              >
                <Input
                  onChange={(e) =>
                    onInputHandleChange("street_no", e.target.value)
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Level No*"
                name="level_no"
                style={{ width: "100%" }}
              >
                <Input
                  onChange={(e) =>
                    onInputHandleChange("level_no", e.target.value)
                  }
                />
              </Form.Item>
            </div>
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Unit No*"
                name="unit_no"
                style={{ width: "100%" }}
              >
                <Input
                  onChange={(e) =>
                    onInputHandleChange("unit_no", e.target.value)
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="basicFormRow">
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Postal Code*"
                name="postal_code"
                style={{ width: "100%" }}
                // rules={[{ required: true, message: 'Please input your postal code!' }]}
              >
                <Input
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    onInputHandleChange("postal_code", e.target.value)
                  }
                />
              </Form.Item>
            </div>
            <div className="besicFormInnerDiv">
              <Form.Item
                label="Country"
                name="country"
                style={{ width: "100%" }}
              >
                {/* <Input
              onChange={(e) => onInputHandleChange("country", e.target.value)}
            /> */}
                <Select
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                  onChange={(e) => onInputHandleChange("country", e)}
                >
                  {countries.map((elem, i) => {
                    return (
                      <Select.Option key={i} value={elem}>
                        {elem}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BasicDetailsForm;
