import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
import styles from "./upcomingEvents.module.css";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Row } from "antd";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import { gsap } from "gsap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment'
const Circle = forwardRef(({ size, delay }, ref) => {
  const el = useRef();

  useImperativeHandle(
    ref,
    () => {
      // return our API
      return {
        moveTo(x, y) {
          // console.log(x, y);
          // console.log(el.current);
          gsap.to(el.current, { x, y, delay: -0.1 });
        },
      };
    },
    []
  );

  return (
    <div className={`circle ${size}`} ref={el}>
      <img
        src="https://img.icons8.com/external-others-inmotus-design/134/external-Close-atm-others-inmotus-design.png"
        style={{ width: "50px" }}
        alt=""
      />
    </div>
  );
});

function OurTeam({ isVisible }) {
    const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const eventslist = [
    {
      name: "Mr. Tamil Marren",
      position: "President",
      descMini: "Managing Director, MccLaren Logistics Pte Ltd.",
      desc: "Managing Director, MccLaren Logistics",
      about: `Mr.Tamil Marren is a well-known Soccer ex-international who represented Singapore in the  1990s. He was a key member of the 1993 Dream Team and the 1994 Malaysia Cup winning  team. He brings a wealth of experience and influence within the sporting communities and  has contributed in many youth academies and grassroots initiatives during his time. He has  actively involved in community events, leadership/ministerial engagements with the  community walkabouts, and charity events in support of less privileged families. Mr.Marren is a long-term IA member, who has also served in multiple Management  Committees in multiple appointments including Soccer Conveynor (8yrs) and recently as VP. He has been proactive to drive membership enrolments of more than 150 new members  over the past 2 years.<br>'The ones who are crazy enough to think they can change the world, are the ones who do'- Steve Jobs`,
      img: "/assets/images/members/Mr Tamil Marren Cropped.jpg",
      fullImg: "/assets/images/members/Mr Tamil Marren Original.jpg",
    },
    {
      name: "Mr. Parthiban Murugaiyan",
      position: "1st Vice President ",
      descMini:
        "Group Chairman of Ishtara Jewellery Pte Ltd, Luvenus Jewellery Pte Ltd,  Luvenus Jewellery Hong Kong. Board Director- Singapore Indian Chambers of Commerce and Industry Board Director &ndash; SMECentre @SICCI",
      desc: "Group Chairman of Ishtara Jewellery, Luvenus Jewellery SICCI – Board Director SmeCentre – Board Director",
      about:
        'Mr.Parthiban ventured into his Jewellery Business at the age of 22 and transformed it into  one for the leading jewellery Merchant in the industry. He is a proven leader with global  vision and extensive entrepreneurial experience gained over many years. He also became  the first local Indian Entrepreneur to be bestowed the overall winner of the Asme-Rotary  Singapore Young Entrepreneur Award in 2014. He has also been accredited as one of the  top 200 Indian Business leaders in Singapore. Mr.Parthiban is currently a Board Director at SICCI, where he has gained credibility and  recognition for his leadership and decision-making abilities. Though his involvements in  Indian community developments, he has also been recognized in the Singapore Tamils  200 Book launched in 2020.\n\n"We cannot be where we want to be by staying where we are".',
      img: "assets/images/members/Parthiban.jpg",
      fullImg: "",
    },
    {
      name: "Ms. Jayanthi Manian",
      position: "2nd Vice President",
      descMini: "Founder & Director of Chase Resource Management Pte Ltd.",
      desc: "Founder & Director of Chase Resource Management",
      about:
        'Ms.Jayanthi, an ex-Singapore Girl, transformed to an aspiring entrepreneur and has  accomplished phenomenal success in the Oil &amp; Gas Industry, which is commonly perceived  to be &quot;Man\'s World&quot;. With her vast knowledge, experience and hard work, she has  expanded her own business into International Markets. She has served in the MOE ASPIRE  review committee to recommend enhancements to the applied education model at the  Polytechnics and ITE. Where possible, she always has a fulfilling experience of giving back  and contributing to society. Ms.Jayanthi has served as a Board Director of SICCI. Her love for the community is still  continuing with her appointment with other Organizations. She has also received many  Business Leader & Commendation Awards.\n"Stop being afraid of what could go wrong, and start being excited about what could go  right" - Tony Robbins',
      img: "assets/images/members/Ms Jayanthi Manian cropped.jpg",
      fullImg: "assets/images/members/Ms Jayanthi Manian Original.jpg",
    },
    {
      name: "Mr. Maneesh Tripathi",
      position: "Finance Member",
      descMini:
        "Vice Chairman - Singapore Indian Chamber of Commerce and Industry  Hon Treasurer - SGtech (CDC) Chapter  Group Chief Executive Officer (CEO) &ndash; Digilife Technologies Ltd (SGX listed)",
      desc: "Group CEO, Digilife Technologies SICCI – Vice Chairman",
      about:
        'Mr.Maneesh Tripathi is a Global citizen who has worked in more than 10 countries in USA,  Middle East, Japan, China, India and SEA, with various Multinational firms. He is currently  managing approximately S$300 million dollar plus listed company. Maneesh has been  recently elected as Vice Chairman of Singapore Indian Chamber Of Commerce (SICCI) in  2020, and the Chairman of Committees leading "Incubation and Startups" and "Bilateral  and Multilateral Trade relations" for Singapore via SICCI Mr.Maneesh has involved in key events planning and execution for G2G (Singapore and  India) large events, and bilateral trade and connectivity discussions. He is an ardent cricket  player and has represented State teams in U-22 Col CK Naidu Trophy',
      img: "assets/images/members/Mr Maneesh Tripathi Cropped.jpg",
      fullImg: "assets/images/members/Mr Maneesh Tripathi Original.jpg",
    },
    {
      name: "Mr. Joseph Pragasam",
      position: "Chairman Board of Games",
      descMini: "Director, Avantis Consulting Pte Ltd.",
      desc: "Director, Avantis Consulting",
      about:
        "Mr.Joseph holds a Bachelor of Engineering Degree from Nanyang Technological  University (NTU). He is Chartered Financial Consultant (ChFC) with more than 20 years of  business and financial planning, technical, sales and marketing experience in the  Engineering and Finance sectors.  He has achieved the Million Dollar Round Table (MDRT) status for 5 straight years, a  Distinguished Toastmaster (DTM) and an ex-international Singapore football player in the  1990s. Mr.Joseph has been proactive in engaging in community development activities. He has  the drive and commitment to implement challenging initiatives and against challenging  and complex requirements. He has previously engaged and enjoys in Youth development  opportunities.",
      img: "assets/images/members/Mr Joseph Pragasm Cropped.jpg",
      fullImg: "assets/images/members/Mr Joseph Pragasm Original.jpg",
    },
    {
      name: "Mr. Yahiya Khan",
      position: "Committee Member",
      descMini: "Managing Director and Chairman of Big Foot Group of Companies",
      desc: "Managing Director and Chairman of BigFoot Group SICCI – Board Director",
      about:
        "Mr.Yahiya Khan founded Big Foot Logistic Pte Ltd in 1992. He started as a casual worker at  the Port of Singapore Authority and had his first business taste at the age of 21. Through  endeavor the company has now established itself as a specialist in a wide range of logistic  services and Seaport Operations. It is also able to achieve 99.9% customer retention over  the past 20 years and certain customers staying loyal for over 20years. He has won  numerous industry awards and most notably the Indian Business Leaders Award for 2016. Mr.Yahiya Khan has served as a Board Director of SICCI. He has actively contributed to  various CSR engagements over the years both at a corporate, Peoples Association and  grassroots level towards local community beneficiaries.",
      img: "assets/images/members/yahya.jpg",
      fullImg: "",
    },
    {
      name: "Mr. Mano Manikkam",
      position: "Committee Member",
      descMini: "Senior Director, VISA",
      desc: "Senior Director, VISA",
      about:
        "Mr.Mano is a Senior Procurement Professional in the Financial Industry with an extensive track record of over 20 years in supporting multinational corporates base across Asia Pacific, Europe and US. He has delivered high value procurement services with spend value of more than USD$2Bn in Strategic Sourcing, achieving significant ROI and sustainable cost savings. He has also successfully led strategic transformational initiatives that delivered positive operational impact. In 1995, Mr. Mano had become the first Singaporean Indian to achieve the distinguished 'Sword of Honour' award in the Officer Cadet School. He is also an MBA graduate. Mr.Mano has keen interests and passion for organizing Indian Cultural and Community engagement events since his school days till now. He has led corporate Employee Engagement and Philanthropy teams and is also core member of local charities supporting  less privileged families in Singapore and Overseas.",
      img: "assets/images/members/Mano Profile Pic Cropped.jpg",
      fullImg: "assets/images/members/Mano Profile Pic.jpeg",
    },
    {
      name: "Mr. Thiru Kumaran",
      position: "Committee Member",
      descMini: "Director, Rio Logistics",
      desc: "Director, Rio Logistics",
      about:
        "Mr. Thiru began his career from humble beginnings and progressed to be a respectable and key partner to a profitable company through hard work and perseverance. Through his  career experiences, he has matured in Leadership and Key decision-making capabilities that allows him to take full ownership of key responsibilities and successfully achieving  desired results. Mr. Thiru is an avid Soccer player who excelled to playing the sport at a semi-pro level and has great interests in organization of big scale sporting events. Mr.Thiru has always had a keen interest in community efforts and participates regularly in annual charity drives. He has supported Youth development initiatives and has been mentoring young talents in sports and enrichment areas.",
      img: "assets/images/members/Mr Thiru Kumaran Cropped.jpg",
      fullImg: "assets/images/members/Mr Thiru Kumaran Original.jpg",
    },
    {
      name: "Mr. Gobi Nathan",
      position: "Committee Member",
      descMini: "Associate Director, AXA Insurance partner of HSBC Life",
      desc: "Associate Director, AXA Insurance",
      about:
        "Mr.Gobi has more than 25years in the Financial Services Industry in both Risk and Personal  Wealth management. He is a very sociable and approachable person always willing to  listen and be the voice of others concerns. Mr. Gobi has also served in many committees  and have both the knowledge and experience to add value. He has served in the  Singapore Cricket Club Finance Committee and Hua Min Primary School Advisory  Committee. He is an active golfer and engages in many social and fund-raising events  through Golf. Through these appointments Mr.Gobi has revitalized and added value to the clubs. He can  certainly drive great value to revamp and revive our sports facilities to better revenue  generating groups. He can also work with Marketing and Promotional initiatives to  reignite sporting groups while managing members benefits too.",
      img: "assets/images/members/Mr Gobi Nathan Cropped.jpg",
      fullImg: "assets/images/members/Mr Gobi Nathan Original.jpg",
    },
    {
      name: "Mrs. Suujatha Nandita Peter",
      position: "Committee Member",
      descMini: "Senior Manager, Social Service Agency",
      desc: "Senior Manager, Social Service Agency",
      about:
        "Mrs.Suujatha works as a Senior Manager managing Youth Disability in a social service  organization serving children and youth with disability, who are in special education and  mainstream schools. She comes with more than 25 years industry experience leading  assignments and holding appointments in various public and corporate organizations,  while supporting key administration and operational areas such as Risk, Governance,  and Administration. Her valuable experiences makes her a well-balanced professional,  effective for any transformational initiatives. She also holds a Degree in Human Resource  Management. Mrs.Suujatha bring positive energy and drive to organizations that she has worked with  to streamline processes and procedures to achieve successful results and prudent  management of resources. She always has a keen interest to share her experiences and  support people who need it most.",
      img: "assets/images/image8.jpg",
      fullImg: "",
    },
    {
      name: "Mr. Kangatharan",
      position: "Committee Member",
      desc: "Legal Profession",
      about:
        "Mr. Kanga is an accomplished lawyer and a former member of Singapore Institute of Arbitration. He participates actively in community engagements and keen interest in youth development initiatives.",
      img: "assets/images/image13.jpg",
      fullImg: "",
    },
    {
      name: "Mr. Kevin Jeevan",
      position: "Committee Member",
      descMini: "Entrepreneur | Recruitment Director, 25 Talents",
      desc: "Entrepreneur | Recruitment Director, 25 Talents",
      about:
        "Mr.Kevin is an Entrepreneur, Aspiring Life Coach, Career and Recruitment Consultant &amp;  Trainer! He has almost 15 years in the recruitment industry and his current focus is on  Technology professionals with a vision to grow into the other sectors. As a driven and  ambitious individual, he is always aspiring to learn new leadership skills and develop  himself to effectively contribute to his organization and community. He has actively  participated and made positive impacts with multiple Youth and Grassroots groups leading  various engagements successfully. He has also served as a Vice Convenor and Captain of  the SGIA Soccer section. Mr.Kevin is equipped with strong interpersonal skills that allows him to engage effectively  with his peers across all age groups. He has made impactful contributions to mentoring  and coaching in youth development initiatives and has great potential to groom and  inspire the next generation of young leaders.",
      img: "assets/images/members/Mr Jeevan Kevin Crop.jpg",
      fullImg: "assets/images/members/Mr Jeevan Kevin Original.jpg",
    },
    // {
    //   name: "Mr. Dinesh Natarajan",
    //   position: "Committee Member",
    //   desc: "Founder and CEO, Trident",
    //   about:
    //     "Mr. Dinesh is a seasoned legal professional who has advised many clients ranging from government entities to MNCs. He is an active representative in Youth development and community initiatives.",
    //   img: "assets/images/image14-1.jpg",
    //   fullImg: "",
    // },
  ];

  const [teamDetails, setTeamDetails] = useState({});
  const [events, setEvents] = useState([eventslist]);
  const circleRefs = useRef([]);
  const teamMemberRef = useRef();
  const navigate = useNavigate()

  circleRefs.current = [];

  useEffect(() => {
    getEvents()
    gsap.to(teamMemberRef.current, { x: window.innerWidth, duration: -1 });
    circleRefs.current.forEach((ref) =>
      ref.moveTo(window.innerWidth / 2, window.innerHeight / 2)
    );

    const onMove = ({ clientX, clientY }) => {
      circleRefs.current.forEach((ref) => ref.moveTo(clientX, clientY));
    };

    window.addEventListener("pointermove", onMove);

    return () => window.removeEventListener("pointermove", onMove);
  }, []);
  const getEvents = async() => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/get-events`).then((res) => {

      setEvents(res.data.result);
    });
  }
  //new added
  // useEffect(() => {
    
  // }, [events]);
  //end
  const addCircleRef = (ref) => {
    if (ref) {
      circleRefs.current.push(ref);
    }
  };

  const openContent = (team) => {
    gsap.to(teamMemberRef.current, { x: 0, duration: 0.5 });
    setTeamDetails(team);
  };

  const closeContent = (e) => {
    gsap.to(teamMemberRef.current, { x: window.innerWidth, duration: 0.5 });
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={styles.ourTeamContainer}>
        <div className={styles.teamContainerInner}>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1.0 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <div className={styles.header}>Upcoming Events</div>
            <hr />
          </motion.div>

          <Swiper
            className={styles.mySwiper}
            slidesPerView={1}
            spaceBetween={50}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 4,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
          >
            {events.map((team, index) => (
              <SwiperSlide key={index}>
                <div
                  onClick={() => {
                    navigate(`/events?id=${team.id}`,)
                    // openContent(team);
                  }}
                  className={`${styles.team} ${styles.teamBackfade}`}
                >
                  <img
                    className={styles.profile}
                    src={team.thumbnail}
                    alt=""
                  />
                  <div className={styles.name}>
                    {team.name}
                    <p className={`mb-0 text-warning`}>{moment(team.date_time).format("DD MMM YYYY")}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* <div
            ref={teamMemberRef}
            className={styles.team_member_detail}
            onClick={closeContent}
          >
            <div class="inner-wrap">
              <Row>
                <Col xs={24} lg={12} style={{ backgroundColor: "#F2F2F2" }}>
                  <div className={styles.team_member_details}>
                    <div class="bio-inner">
                      <span class="mobile-close"></span>
                      <h1 className={styles.text_center_our_team}>
                        {teamDetails.name}
                      </h1>
                      <br />
                      <div className={styles.text_center_our_team}>
                        <p>{teamDetails.description}</p>
                        <div class="bottom_meta"></div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} lg={12} style={{ height: "100vh" }}>
                  <div >
                    <div ></div>
                    <div
                    className={styles.team_member_picture_wrap}
                      
                      style={{ padding: "unset" }}
                    >
                      {teamDetails.thumbnail ? (
                        <img
                          style={{ objectFit: "cover", height: "100vh" }}
                          src={teamDetails.thumbnail}
                          alt=""
                        />
                      ) : (
                        <img
                          style={{ objectFit: "cover", height: "100vh" }}
                          src={teamDetails.thumbnail}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <Circle size="sm" ref={addCircleRef} delay={0} />
          </div> */}
          <Link to="upcoming-events">
            <button
            className={styles.button}
              onClick={scrollToTop}
              style={{
                cursor: "pointer",
                fontSize: "16px",
                marginBottom: "25px",
                padding: "12px 31px",
              }}
            >
              Find More
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default OurTeam;
