import React from "react";
import { useState, useEffect } from "react";
import styles from "./JoinUs.module.css";
import { Button, Divider, Radio, Space, message } from "antd";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Checkbox, Form, Select } from "antd";
import MembershipFormSubmission from "./successDetail";
import { calculatePercent } from "../../utils/GST";
import { paymentUrl } from "../../Services/BusinessLogic";
const JoinUs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [success, setSuccess] = useState(true);
  const [sportsData, setSportsData] = useState({});
  const [taxAndFees, setTaxAndFees] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [taxType, setTaxType] = useState("percent");
  const [Pdf, setPdf] = useState("");
  const [serviceType, setServiceType] = useState("percent");

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const status = searchParams.get("status");
  // const type = searchParams.get("type");
  let storedToken = localStorage.getItem("token");
  const elem = location.state
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchData();
    if (status === "success") {
      setSuccess(false);
    }
    getTermsAndConditions();
  }, []);


  const getTermsAndConditions = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/cms`, {
        name: "terms_and_conditions",
      });
      setPdf(res.data.result.content);
    } catch (err) {
      message.error(err.message);
    }
  };
  const paymentStatus = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/get-status-by-payment-id`,
        {
          payment_id: localStorage.getItem("payment_id"),
          // user_id:
        },
        {
          headers: {
            content: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log(response.data);
      localStorage.removeItem("payment_id");
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };
  useEffect(() => {
    if (success === false) {
      paymentStatus();
    }
  }, [success]);

  const fetchData = async () => {
    let res1 = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/sports-club-all?id=${id}`
    );
    console.log(res1);
    setSportsData(res1.data.result[0]);
    setTaxAndFees(
      res1.data.service_charges[0]?.percent
        ? res1.data.service_charges[0]?.percent
        : res1.data.service_charges[0]?.value
    );
    setTaxType(res1.data.service_charges[0]?.percent ? "percent" : "value");
    setServiceCharge(
      res1.data.service_charges[1]?.percent
        ? res1.data.service_charges[1]?.percent
        : res1.data.service_charges[1]?.value
    );
    setServiceType(res1.data.service_charges[1]?.percent ? "percent" : "value");
  };
  const onInputChange = (label, e) => {
    form.setFieldsValue(label, e.target.value);
  };

  const functionForPayment = async (Id, club_history_id) => {
    setLoading(true);
    const res = await paymentUrl(
      Id,
      localStorage.getItem("user_id"),
      "sport",
      sportsData?.fee_for_member 
        ,
      "",
      id,
      club_history_id,
      calculatePercent(taxAndFees, sportsData?.fee_for_member ,taxType) ,
      calculatePercent(serviceCharge, sportsData?.fee_for_member ,serviceType)
    );
    if (res) {
      window.location.href = res.paymentLink;
    }
    setLoading(false);
  };
  const addClub = async () => {
    console.log(form.getFieldValue("remember"));
    if (!form.getFieldValue("remember") || !form.getFieldValue("remember2")) {
      message.error("Please Check Declaration !");
      return;
    }
    let data = {
      id: id,
      term: elem?.addMember?.status==="expired"?"renew":"new",
    };
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/sports-club-new`, data, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.data.id);
          functionForPayment(
            response.data?.data?.id,
            response.data?.data?.club_history_id
          );
          // navigate(`/SportsSection/view-details?id=${id}&type=all`);
        }
      })
      .catch((err) => {
        message.warn("Please login");
      });
  };
  return (
    <div className="marginTopFromNavbar container">
      <div className="p-5"></div>
      <div>
        <div className={styles.heading}>SPORTS SECTION</div>
        <div className={styles.path}>
          Home - Bookings - Sports Section - All Sports Section -{" "}
          {sportsData ? sportsData.name : ""} - Join Club
        </div>
        {success ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "60px",
            }}
          >
            <div className={styles.containerBox}>
              <div className={styles.containerHeading}>
                Sports Section Membership
              </div>
              <div className={styles.smallBox}>
                <div className={styles.flexBox}>
                  <div className={styles.smallBoxHeading}>
                    {sportsData ? sportsData.name : ""}
                  </div>
                  <div className={styles.smallBoxAns}>
                    S${sportsData && sportsData?.fee_for_member?.toFixed(2)}
                    {/* <br /> */}
                    {/* <div className={styles.forMembers}>For Members</div> */}
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div>
                    <span className={styles.smallBoxHeading}>Validity: </span>{" "}
                    <span className={styles.date}>1 Month</span>
                  </div>
                  {/* <div>
                  S$50.00
                </div> */}
                </div>
              </div>
              <Divider />
              <div className={styles.flexBox}>
                <div className={styles.resultHeading}>Subtotal</div>
                <div className={styles.resultAnswer}>
                  S${sportsData && sportsData?.fee_for_member?.toFixed(2)}
                </div>
              </div>
              <div className={styles.flexBox}>
                <div className={styles.resultHeading}>GST</div>
                <div className={styles.resultAnswer}>
                  S$
                  {calculatePercent(
                    taxAndFees,
                    sportsData?.fee_for_member,
                    taxType
                  )?.toFixed(2)}
                </div>
              </div>

              <div className={styles.flexBox}>
              <div className={styles.resultHeading}>Service Charge</div>
                <div className={styles.resultAnswer}>
                  S$
                  {calculatePercent(
                    serviceCharge,
                    sportsData?.fee_for_member,
                    serviceType
                  )?.toFixed(2)}
                </div>
              </div>

             
              <div className={styles.flexBox}>
                {/* <div className={styles.resultHeading}>
           Discount
            </div>
            <div className={styles.resultAnswer}>
              S$0.00
            </div> */}
              </div>
              <Divider />
              <div className={styles.flexBox}>
                <div className={styles.resultAnswer}>Total</div>
                <div className={styles.resultAnswer}>
                  S$
                  {sportsData &&
                    parseFloat(
                      sportsData?.fee_for_member +
                        calculatePercent(
                          taxAndFees,
                          sportsData?.fee_for_member,
                          taxType
                        ) +
                        calculatePercent(
                          serviceCharge,
                          sportsData?.fee_for_member,
                          serviceType
                        )
                    )?.toFixed(2)}
                </div>
              </div>
            </div>
            <Form style={{ marginTop: "30px" }} form={form}>
              <div className={styles.smallBoxHeading}>Declaration</div>
              <div
                className="d-flex align-items-center"
                style={{ width: "50vw" }}
              >
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox
                    onChange={(e) => onInputChange("remember", e)}
                  ></Checkbox>
                </Form.Item>
                <p className="m-0">
                  I/We declare that the information given in this membership
                  form is true and correct to the best of my knowledge and
                  belief.
                </p>
              </div>
              <div className="d-flex align-items-center">
                <Form.Item name="remember2" valuePropName="checked">
                  <Checkbox
                    onChange={(e) => onInputChange("remember2", e)}
                  ></Checkbox>
                </Form.Item>
                <p className="m-0">
                  I agree to abide by SGIA rules & regulations as present or as
                  maybe changed from time to time. <a style={{display:"inline"}} target="_blank" href={Pdf}>Terms and Conditions</a>
                </p>
              </div>
              <div>
                <Button
                  type="danger"
                  loading={loading}
                  style={{ width: "100%" }}
                  onClick={addClub}
                >
                  BUY MEMBERSHIP
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <MembershipFormSubmission />
        )}
      </div>
    </div>
  );
};

export default JoinUs;
