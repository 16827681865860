import React from 'react'

import './EventsFacility.css'

function Events() {
  return (
    <div className='Card_Events'>
        <div class="recommended">Recommended (4)</div>
        <div className="FacilityEvent_main">
        <div className='FacilityEvent'>
            <img src="/images/event3.png"/>
            <div style={{padding:"24px"}}>
            <div className='FacilityEvent_name'>Indoor Hall</div>
            <div className='FacilityEvent_desc'>Celebrate With Friends And Family</div>
            <div className='FacilityEvent_info'>
                <div style={{display:'flex'}}>
            <div style={{marginRight:'5px'}}className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />
                        </div>
                <div className='FacilityEvent_timings'>
                    <div>Business Hrs</div>
                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                    <div class="events-info-timing">Sat-Sun Closed</div>
                </div>
                </div>
                <div style={{display:'flex'}}>
                <div style={{marginRight:'5px'}}className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
                <div className='FacilityEvent_fees'>
               
                    <div>FEES</div>
                    <div class="events-info-timing">0$ for members</div>
                    <div class="events-info-timing">50$ for non members</div>
                </div>
                </div>
            </div>
            <div style={{display:'flex'}}>
            <div style={{marginRight:'5px'}} className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
            <div className='FaciltyEvent_booking_hrs'>
                <div>Booking Hrs</div>
                <div class="events-info-timing">4 Hrs/Booking</div>
            </div>
            </div>

            <button className='facilityEvent_book'>Book Now</button>
            </div>

            

        </div>

        <div className='FacilityEvent'>
            <img src="/images/event3.png"/>
            <div style={{padding:"24px"}}>
            <div className='FacilityEvent_name'>Indoor Hall</div>
            <div className='FacilityEvent_desc'>Celebrate With Friends And Family</div>
            <div className='FacilityEvent_info'>
            <div style={{display:'flex'}}>
            <div style={{marginRight:'5px'}}className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />
                        </div>
                <div className='FacilityEvent_timings'>
                    <div>Business Hrs</div>
                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                    <div class="events-info-timing">Sat-Sun Closed</div>
                </div>
                </div>
                <div style={{display:'flex'}}>
                <div style={{marginRight:'5px'}}className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
                <div className='FacilityEvent_fees'>
               
                    <div>FEES</div>
                    <div class="events-info-timing">0$ for members</div>
                    <div class="events-info-timing">50$ for non members</div>
                </div>
                </div>
            </div>
            <div style={{display:'flex'}}>
            <div style={{marginRight:'5px'}} className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
            <div className='FaciltyEvent_booking_hrs'>
                <div>Booking Hrs</div>
                <div class="events-info-timing">4 Hrs/Booking</div>
            </div>
            </div>

            <button className='facilityEvent_book'>Book Now</button>
            </div>

            

        </div>
        
        </div>

        <div className="FacilityEvent_main">
        <div className='FacilityEvent'>
            <img src="/images/event3.png"/>
            <div style={{padding:"24px"}}>
            <div className='FacilityEvent_name'>Indoor Hall</div>
            <div className='FacilityEvent_desc'>Celebrate With Friends And Family</div>
            <div className='FacilityEvent_info'>
            <div style={{display:'flex'}}>
            <div style={{marginRight:'5px'}}className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />
                        </div>
                <div className='FacilityEvent_timings'>
                    <div>Business Hrs</div>
                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                    <div class="events-info-timing">Sat-Sun Closed</div>
                </div>
                </div>
                <div style={{display:'flex'}}>
                <div style={{marginRight:'5px'}}className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
                <div className='FacilityEvent_fees'>
               
                    <div>FEES</div>
                    <div class="events-info-timing">0$ for members</div>
                    <div class="events-info-timing">50$ for non members</div>
                </div>
                </div>
            </div>
            <div style={{display:'flex'}}>
            <div style={{marginRight:'5px'}} className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
            <div className='FaciltyEvent_booking_hrs'>
                <div>Booking Hrs</div>
                <div class="events-info-timing">4 Hrs/Booking</div>
            </div>
            </div>

            <button className='facilityEvent_book'>Book Now</button>
            </div>

            

        </div>

        <div className='FacilityEvent'>
            <img src="/images/event3.png"/>
            <div style={{padding:"24px"}}>
            <div className='FacilityEvent_name'>Indoor Hall</div>
            <div className='FacilityEvent_desc'>Celebrate With Friends And Family</div>
            <div className='FacilityEvent_info'>
            <div style={{display:'flex'}}>
            <div style={{marginRight:'5px'}}className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />
                        </div>
                <div className='FacilityEvent_timings'>
                    <div>Business Hrs</div>
                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                    <div class="events-info-timing">Sat-Sun Closed</div>
                </div>
                </div>
                <div style={{display:'flex'}}>
                <div style={{marginRight:'5px'}}className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
                <div className='FacilityEvent_fees'>
               
                    <div>FEES</div>
                    <div class="events-info-timing">0$ for members</div>
                    <div class="events-info-timing">50$ for non members</div>
                </div>
                </div>
            </div>
            <div style={{display:'flex'}}>
            <div style={{marginRight:'5px'}} className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
            <div className='FaciltyEvent_booking_hrs'>
                <div>Booking Hrs</div>
                <div class="events-info-timing">4 Hrs/Booking</div>
            </div>
            </div>
            <button className='facilityEvent_book'>Book Now</button>
            </div>

            

        </div>
        
        </div>
    </div>
  )
}

export default Events