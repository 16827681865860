import { Form, Input } from 'antd'
import React, { useEffect } from 'react'

function ProposerForm({ onInputHandleChange }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      //   behavior: "smooth",
    });

  }, [])
  return (
    <div>
      <p className="font-weight-bold">Proposer</p>
      <div className="basicFormRow">
        <div className="besicFormInnerDiv">
          <Form.Item label='Name*' name='proposer_name' style={{ width: "100%" }}>
            <Input
              onChange={(e) => onInputHandleChange('proposer_name', e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Backspace" || e.key === "Enter") {
                  return;
                }
                if (!/^[a-zA-Z ]$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="besicFormInnerDiv">
          <Form.Item label='Membership UID*' name='proposer_membership_no' style={{ width: "100%" }}>
            <Input onChange={(e) => onInputHandleChange('proposer_membership_no', e.target.value)} />
          </Form.Item>
        </div>
      </div>
      <div className="basicFormRow">
        <div className="besicFormInnerDiv">
          <Form.Item label='Mobile Number*' name='proposer_mobile_number' style={{ width: "100%" }}>
            <Input onKeyPress={(e) => {
              const charCode = e.which || e.keyCode;
              if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                e.preventDefault();
              }
            }} onChange={(e) => onInputHandleChange('proposer_mobile_number', e.target.value)} />
          </Form.Item>
        </div>
        {/* <div className="besicFormInnerDiv">
          <Form.Item label='Home Contact Number**' name='proposer_home_contact_number' style={{ width: "100%" }}>
            <Input onKeyPress={(e) => {
              const charCode = e.which || e.keyCode;
              if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                e.preventDefault();
              }
            }} onChange={(e) => onInputHandleChange('proposer_home_contact_number', e.target.value)} />
          </Form.Item>
        </div> */}
      </div>
      <p className="font-weight-bold">Seconder</p>
      <div className="basicFormRow">
        <div className="besicFormInnerDiv">
          <Form.Item label='Name*' name='secondar_name' style={{ width: "100%" }}>
            <Input onChange={(e) => onInputHandleChange('secondar_name', e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Backspace" || e.key === "Enter") {
                  return;
                }
                if (!/^[a-zA-Z ]$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="besicFormInnerDiv">
          <Form.Item label='Membership UID*' name='secondar_membership_no' style={{ width: "100%" }}>
            <Input onChange={(e) => onInputHandleChange('secondar_membership_no', e.target.value)} />
          </Form.Item>
        </div>
      </div>
      <div className="basicFormRow">
        <div className="besicFormInnerDiv">
          <Form.Item label='Mobile Number*' name='secondar_mobile_number' style={{ width: "100%" }}>
            <Input onKeyPress={(e) => {
              const charCode = e.which || e.keyCode;
              if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                e.preventDefault();
              }
            }} onChange={(e) => onInputHandleChange('secondar_mobile_number', e.target.value)} />
          </Form.Item>
        </div>
        {/* <div className="besicFormInnerDiv">
          <Form.Item label='Home Contact Number**' name='secondar_home_contact_number' style={{ width: "100%" }}>
            <Input onKeyPress={(e) => {
              const charCode = e.which || e.keyCode;
              if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                e.preventDefault();
              }
            }} onChange={(e) => onInputHandleChange('secondar_home_contact_number', e.target.value)} />
          </Form.Item>
        </div> */}
      </div>
    </div>
  )
}

export default ProposerForm