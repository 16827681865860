import { Divider, Tag, Timeline } from "antd";
import React from "react";
import "./viewDetails.css";
import { Table } from "antd";
import Search from "antd/lib/transfer/search";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

import { Image, InputNumber, message } from "antd";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import { TimePicker } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const ViewDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const club_id = searchParams.get("club_id");
  const activiti_id = searchParams.get("activiti_id");
  const [bookingData, setBookingData] = useState({});
  const [activities, setActivities] = useState([]);
  const [storedToken, setStoredToken] = useState("");
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const type = searchParams.get("type");

  const fetchData = async () => {
    let res1 = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/sports-club-activities?sports_section_id=${club_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res1);
    setBookingData(res1.data.result[0]);
    let res2 = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/sports-club-activities?sports_section_id=${club_id}&id=${activiti_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res2.data.result[0].details);
    setActivities(res2.data.result[0].details);
  };
  useEffect(() => {
    setStoredToken(localStorage.getItem("token"));
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
    fetchData();
  }, []);
  return (
    <div className="marginTopFromNavbar sportsectionviewdetail">
      <div className="container">
        <h2 className="topHeading mb-0 text-left">{bookingData.name}</h2>
        {/* <Tag
          className="heading-2"
          style={{ border: "none", fontSize: "16px" }}
          bordered={false}
          color="green"
        >
          10 Seats Available
        </Tag> */}
        <div className="d-flex flex-wrap mt-5">
          <div className="topImage">
            <img src={bookingData.thumbnail} alt="..." />
          </div>
          <div
            style={{ gap: "4px" }}
            className="d-flex w-50 flex-wrap imagesSecondBox align-items-center justify-content-center"
          >
            <div className="topImage">
              <img src={bookingData.thumbnail} alt="..." />
            </div>
            <div className="topImage">
              <img src={bookingData.thumbnail} alt="..." />
            </div>
            <div className="topImage">
              <img src={bookingData.thumbnail} alt="..." />
            </div>
            <div className="topImage">
              <img src={bookingData.thumbnail} alt="..." />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "35px" }}>
          <h2>Description</h2>
          <p>{bookingData.description}</p>
        </div>

        <Divider />
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="history-title-holder"
            style={{
              
              marginBottom: "50px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >ITENARY</div>
          <div className="red-highlight"></div>
        </div>
        {/* <div className="detailTable">
          <h2>Our Memebrs</h2>
          <div className="our-member-search">
            {" "}
            <Search placeholder="Search" onSearch={onSearch} />{" "}
          </div>
          <Table columns={columns} dataSource={data} />
        </div> */}
      </div>
      <div className="container">
        <Timeline>
          {activities.length !== 0 &&
            activities.map((elem, i) => {
              return (
                <Timeline.Item color="green">
                  <h3>
                    {moment.utc(elem.date_time).local().format("HH:mm")} ~
                    {elem.heading} ~ {elem.name}
                  </h3>
                  <p>{elem.description}</p>
                </Timeline.Item>
              );
            })}
          {/* <Timeline.Item color="green">
            <h3>
              10:30 ~Loreum ipsum is dummy text ~ Loreum ipsum is dumy
              text.Loreum ipsum is dumy text.Loreum ipsum is dumy text.
            </h3>
            <p>Loreum ipsum is dumy text.Loreum ipsum is dumy text.</p>
            <p>Loreum ipsum is dumy text.Loreum ipsum is dumy text.</p>
            <p>Loreum ipsum is dumy text.Loreum ipsum is dumy text.</p>
            <p>Loreum ipsum is dumy text.Loreum ipsum is dumy text.</p>
          </Timeline.Item>
          <Timeline.Item color="red">
            <h3>
              01:30 ~Loreum ipsum is dummy text ~ Loreum ipsum is dumy
              text.Loreum ipsum is dumy text.Loreum ipsum is dumy text.
            </h3>
          </Timeline.Item>
          <Timeline.Item>
            <h3>
              02:45 ~Loreum ipsum is dummy text ~ Loreum ipsum is dumy
              text.Loreum ipsum is dumy text.Loreum ipsum is dumy text.
            </h3>
          </Timeline.Item>
          <Timeline.Item color="yellow">
            <h3>
              03:35 ~Loreum ipsum is dummy text ~ Loreum ipsum is dumy
              text.Loreum ipsum is dumy text.Loreum ipsum is dumy text.
            </h3>
          </Timeline.Item>
          <Timeline.Item color="red">
            <h3>
              05:30 ~Loreum ipsum is dummy text ~ Thank you for attending our
              activity!!!
            </h3>
          </Timeline.Item> */}
        </Timeline>
      </div>
    </div>
  );
};

export default ViewDetails;

const BusinessHourIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_2940_10557)">
      <path
        d="M10 16V15H3.01L3 19C3 20.11 3.89 21 5 21H19C20.11 21 21 20.11 21 19V15H14V16H10ZM20 7H15.99V5L13.99 3H9.99L7.99 5V7H4C2.9 7 2 7.9 2 9V12C2 13.11 2.89 14 4 14H10V12H14V14H20C21.1 14 22 13.1 22 12V9C22 7.9 21.1 7 20 7ZM14 7H10V5H14V7Z"
        fill="#1E73BE"
      />
    </g>
    <defs>
      <clipPath id="clip0_2940_10557">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const FeeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
  >
    <g clip-path="url(#clip0_2940_10563)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13.41 18.09V18.67C13.41 19.4 12.81 20 12.08 20H12.07C11.34 20 10.74 19.4 10.74 18.67V18.07C9.41 17.79 8.23 17.06 7.73 15.83C7.5 15.28 7.93 14.67 8.53 14.67H8.77C9.14 14.67 9.44 14.92 9.58 15.27C9.87 16.02 10.63 16.54 12.09 16.54C14.05 16.54 14.49 15.56 14.49 14.95C14.49 14.12 14.05 13.34 11.82 12.81C9.34 12.21 7.64 11.19 7.64 9.14C7.64 7.42 9.03 6.3 10.75 5.93V5.33C10.75 4.6 11.35 4 12.08 4H12.09C12.82 4 13.42 4.6 13.42 5.33V5.95C14.8 6.29 15.67 7.15 16.05 8.21C16.25 8.76 15.83 9.34 15.24 9.34H14.98C14.61 9.34 14.31 9.08 14.21 8.72C13.98 7.96 13.35 7.47 12.09 7.47C10.59 7.47 9.69 8.15 9.69 9.11C9.69 9.95 10.34 10.5 12.36 11.02C14.38 11.54 16.54 12.41 16.54 14.93C16.52 16.76 15.15 17.76 13.41 18.09Z"
        fill="#1E73BE"
      />
    </g>
    <defs>
      <clipPath id="clip0_2940_10563">
        <rect width="21.6562" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const MemberIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_2940_10569)">
      <path
        d="M12 12.75C13.63 12.75 15.07 13.14 16.24 13.65C17.32 14.13 18 15.21 18 16.38V18H6V16.39C6 15.21 6.68 14.13 7.76 13.66C8.93 13.14 10.37 12.75 12 12.75ZM4 13C5.1 13 6 12.1 6 11C6 9.9 5.1 9 4 9C2.9 9 2 9.9 2 11C2 12.1 2.9 13 4 13ZM5.13 14.1C4.76 14.04 4.39 14 4 14C3.01 14 2.07 14.21 1.22 14.58C0.48 14.9 0 15.62 0 16.43V18H4.5V16.39C4.5 15.56 4.73 14.78 5.13 14.1ZM20 13C21.1 13 22 12.1 22 11C22 9.9 21.1 9 20 9C18.9 9 18 9.9 18 11C18 12.1 18.9 13 20 13ZM24 16.43C24 15.62 23.52 14.9 22.78 14.58C21.93 14.21 20.99 14 20 14C19.61 14 19.24 14.04 18.87 14.1C19.27 14.78 19.5 15.56 19.5 16.39V18H24V16.43ZM12 6C13.66 6 15 7.34 15 9C15 10.66 13.66 12 12 12C10.34 12 9 10.66 9 9C9 7.34 10.34 6 12 6Z"
        fill="#1E73BE"
      />
    </g>
    <defs>
      <clipPath id="clip0_2940_10569">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
