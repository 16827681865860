import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FileDoneOutlined } from "@ant-design/icons";
import "./EventsFacilityBookingCOnfirmed.css";
import moment from "moment"
import QRCode from "react-qr-code";
import { Button, message } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
function EventsFacilityBookingConfirmed() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const _id = searchParams.get("id");
  const facilityType = searchParams.get("type");
  // const bookingName= searchParams.get("bookingName");
  const total_amount = searchParams.get("total_amount");
  const intentId = useSelector((state) => state.paymentIntent);
  const dispatch = useDispatch()
  const bookingName = localStorage.getItem("bookingName");
  console.log(_id);
  const convertDivToPDFandDownload = async (divId) => {
    const input = document.getElementById(divId); // Id of the div containing the content
    const inputWidth = input.offsetWidth;
    const inputHeight = input.offsetHeight;
  
    // Clone the input div
    const clone = input.cloneNode(true);
  
    // Set the clone style to position it off-screen
    clone.style.position = 'absolute';
    clone.style.left = '-9999px';
    clone.style.top = '-9999px';
    clone.style.visibility= "visible"
  
    // Append the clone to the document body
    document.body.appendChild(clone);
  
    // Capture the content of the clone
    html2canvas(clone, { scrollY: -window.scrollY, scrollX: -window.scrollX, width: inputWidth, height: inputHeight })
      .then((canvas) => {
        // Generate PDF from the captured canvas
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'px', [inputWidth, inputHeight]);
        pdf.addImage(imgData, 'PNG', 0, 0, inputWidth, inputHeight, '', 'SLOW');
        pdf.save('downloaded.pdf');
  
        // Remove the clone from the document body
        document.body.removeChild(clone);
        
        // Hide the original div
        
      })
      .catch((error) => {
        console.error('Error capturing canvas:', error);
        // Remove the clone from the document body in case of error
        document.body.removeChild(clone);
      });
  };
  
  const EventsFacilityBookingConfirmed = () => {
    // Rest of your component code
    
    const downloadQRCode = async () => {
      try {
        await convertDivToPDFandDownload("svgQr");
        console.log("PDF download completed.");
      } catch (error) {
        console.error("PDF download failed:", error);
      }
    };
  
    // Rest of your component code
  };
  
  

  const downloadQRCode = async () => {
    try {
      await convertDivToPDFandDownload("svgQr");
      console.log("PNG download completed.");
    } catch (error) {
      console.error("PNG download failed:", error);
    }
  };

  // const checkStatus = async () => {
  //   try{
  //     const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-status-by-payment-id`,{
  //       "payment_id":intentId
  //     },{
  //       headers:{
  //         "content":"application/json",
  //         Authorization: `Bearer ${storedToken}`,
  //       }
  //     })
  //     console.log(response.data)
  //     dispatch(setPayment(null))
      
  //   }catch(err){
  //     console.log(err)
  //     message.error("Something went wrong")
  //   }
  // }
  console.log(intentId)
 useEffect(()=>{
  if(!_id || !facilityType || !bookingName || !total_amount){
   
    // window.location.href="/"
  }
  else{
    // checkStatus();


    paymentStatus();
  }
 },[])

 const paymentStatus = async () => {
  try{
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-status-by-payment-id`,{
      
              "payment_id": localStorage.getItem("payment_id"),
          // user_id:
        },{
          headers:{
            "content":"application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        
        })
        
        console.log(response.data)
        localStorage.removeItem("payment_id")
        localStorage.removeItem("bookingName")
        
  
  }catch(err){
      console.log(err)
      message.error("Something went wrong")
  }

}


  return (
    <div className="marginTopFromNavbar">
      {/* <div className="events_component_1">
        <div className="events_1">
          <h1>EVENTS FACILITY</h1>
        </div>
        <div className="events_2">
          Home - Bookings - Events Facility - Checkout
        </div>
      </div> */}
      <div className="py-1">

      </div>

      <div className="EventsFacilityBooking_component">
        <div className="booking_confirmed_flex" style={{paddingRight:"0px"}}>
          <div>
            <FileDoneOutlined style={{ fontSize: "450%" }} />
          </div>
          <div className="booking_confirmed_desc">
            <div>Your booking request is submitted successfully !</div>
            <div>
              {/* Your booking for selected {facilityType==='facility' ? 'facility' : 'Event'} is confirmed. Please show the
              below QR code or booking id at the entry. */}
              Once your booking is confirmed, please show the below QR code or booking id at entry !
            </div>
          </div>
        </div>
        <div className="EventsFacilityBookingConfirmed_main">
          <div className="EventsFacilityBookingConfirmed_main_one">
            <div className="EventsFacilityBookingConfirmed_main_head">
              Booking Details
            </div>
            <div className="EventsFacilityBookingConfirmed_main_flex">
              <div style={{width:"100%"}}>
                <div className="label">Booking Id</div>
                <div className="down">{_id}</div>
              </div>
              <div style={{width:"100%"}}> 
                <div className="label">{facilityType==='facility' ? 'Facility' : 'Event'}</div>
                <div className="down">{bookingName}</div>
              </div>
            </div>
            <div className="EventsFacilityBookingConfirmed_main_flex">
              <div style={{width:"100%"}}>
                <div className="label">Date of booking</div>
                <div className="down">{moment().format("DD MMM YYYY")}</div>
              </div>
              <div style={{width:"100%"}}>
                <div className="label">Amount Paid</div>
                <div className="down">S${total_amount}</div>
              </div>
            </div>

            <div className="booking_thank_You">
              Thank you for booking SGIA {facilityType==='facility' ? 'facility' : 'Event'}. We hope you enjoy your
              experience at SGIA {facilityType==='facility' ? 'facility' : 'Event'}!
            </div>
            <div className="booking_thank_You">Greetings!</div>
          </div>
          <div className="EventsFacilityBookingConfirmed_main_two">
            {/* <img src="./images/qr.png" /> */}
            {/* <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 64,
                width: "100%",
              }}
            > */}
            <div id="myqrcode">
              <QRCode
                
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={_id}
                viewBox={`0 0 256 256`}
              />
            </div>
            <Button type="primary" onClick={downloadQRCode}>
              Download
            </Button>
            {/* <div>DOWNLOAD QR CODE</div> */}
          </div>
        </div>
      </div>

      <div style={{width:"50%",position:"absolute",visibility:"hidden"}}  id="svgQr">
        <div className="booking_confirmed_flex booking_confirmed_flex_2" style={{paddingRight:0}}>
          <div>
            <FileDoneOutlined style={{ fontSize: "450%" }} />
          </div>
          <div className="booking_confirmed_desc booking_confirmed_desc_2">
            <div>Your booking request is submitted successfully !</div>
            <div>
              {/* Your booking for selected {facilityType==='facility' ? 'facility' : 'Event'} is confirmed. Please show the
              below QR code or booking id at the entry. */}
              Once your booking is confirmed, please show the below QR code or booking id at entry !
            </div>
          </div>
        </div>
        <div className="EventsFacilityBookingConfirmed_main EventsFacilityBookingConfirmed_main_2">
          <div className="EventsFacilityBookingConfirmed_main_one EventsFacilityBookingConfirmed_main_one_2">
            <div className="EventsFacilityBookingConfirmed_main_head EventsFacilityBookingConfirmed_main_head_2">
              Booking Details
            </div>
            <div className="EventsFacilityBookingConfirmed_main_flex EventsFacilityBookingConfirmed_main_flex_2">
              <div style={{width:"100%"}}>
                <div className="label">Booking Id</div>
                <div className="down">{_id}</div>
              </div>
              <div style={{width:"100%"}}> 
                <div className="label">{facilityType==='facility' ? 'Facility' : 'Event'}</div>
                <div className="down">{bookingName}</div>
              </div>
            </div>
            <div className="EventsFacilityBookingConfirmed_main_flex EventsFacilityBookingConfirmed_main_flex_2">
              <div style={{width:"100%"}}>
                <div className="label">Date of booking</div>
                <div className="down">{moment().format("DD MMM YYYY")}</div>
              </div>
              <div style={{width:"100%"}}>
                <div className="label">Amount Paid</div>
                <div className="down">S${total_amount}</div>
              </div>
            </div>

            <div className="booking_thank_You">
              Thank you for booking SGIA {facilityType==='facility' ? 'facility' : 'Event'}. We hope you enjoy your
              experience at SGIA {facilityType==='facility' ? 'facility' : 'Event'}!
            </div>
            <div className="booking_thank_You">Greetings!</div>
          </div>
          <div className="EventsFacilityBookingConfirmed_main_two EventsFacilityBookingConfirmed_main_two_2">
            {/* <img src="./images/qr.png" /> */}
            {/* <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 64,
                width: "100%",
              }}
            > */}
            <div id="myqrcode">
              <QRCode
                
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={_id}
                viewBox={`0 0 256 256`}
              />
            </div>
           
            {/* <div>DOWNLOAD QR CODE</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsFacilityBookingConfirmed;
