import { Form, Input, Select } from "antd";
import React, { useEffect } from "react";

function NextofKinForm({ onInputHandleChange }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      //   behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <p className="font-weight-bold">Emergency Contact Person 1</p>
      <div className="basicFormRow">
        <div className="besicFormInnerDiv">
          <Form.Item
            label="Name*"
            name="emergency_1_name"
            style={{ width: "100%" }}
          >
            <Input
              onChange={(e) =>
                onInputHandleChange("emergency_1_name", e.target.value)
              }
              // onKeyPress={(e) => {
              //   const charCode = e.which || e.keyCode;
              //   if (charCode >= 48 && charCode <= 57) {
              //     e.preventDefault();
              //   }
              // }}
              onKeyDown={(e) => {
                // Allow Backspace and Enter keys
                if (e.key === "Backspace" || e.key === "Enter") {
                  return;
                }
                // Allow only alphabetic characters and spaces
                if (!/^[a-zA-Z ]$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="besicFormInnerDiv">
          <Form.Item
            label="Relationship*"
            name="emergency_1_relationship"
            style={{ width: "100%" }}
          >
            {/* <Input
              onChange={(e) =>
                onInputHandleChange("emergency_1_relationship", e.target.value)
              }
            /> */}
            <Select
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp="children"
            showSearch
              style={{ width: "100%" }}
              onChange={(e) =>
                onInputHandleChange("emergency_1_relationship", e)
              }
            >
              <Select.Option value="Parent">Parent</Select.Option>
              <Select.Option value="Legal Guardian">
                Legal Guardian
              </Select.Option>
              <Select.Option value="Spouse">Spouse</Select.Option>
              <Select.Option value="Significant Other">
                Significant Other
              </Select.Option>
              <Select.Option value="Child">Child</Select.Option>
              <Select.Option value="Friend">Friend</Select.Option>
              <Select.Option value="Case Worker">Case Worker</Select.Option>
              <Select.Option value="Primary Care Provider">
                Primary Care Provider
              </Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="basicFormRow">
        <div className="besicFormInnerDiv">
          <Form.Item
            label="Mobile Number*"
            name="emergency_1_mobile_number"
            style={{ width: "100%" }}
          >
            <Input
              onChange={(e) =>
                onInputHandleChange("emergency_1_mobile_number", e.target.value)
              }
              onKeyPress={(e) => {
                const charCode = e.which || e.keyCode;
                if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                  e.preventDefault();
                }
              }} 
            />
          </Form.Item>
        </div>
        <div className="besicFormInnerDiv">
          <Form.Item
            label="Home Contact Number"
            name="emergency_1_home_contact_number"
            style={{ width: "100%" }}
          >
            <Input
              onKeyPress={(e) => {
                const charCode = e.which || e.keyCode;
                if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                  e.preventDefault();
                }
              }} 
              onChange={(e) =>
                onInputHandleChange(
                  "emergency_1_home_contact_number",
                  e.target.value
                )
              }
            />
          </Form.Item>
        </div>
      </div>
      <p className="font-weight-bold">Emergency Contact Person 2</p>
      <div className="basicFormRow">
        <div className="besicFormInnerDiv">
          <Form.Item
            label="Name*"
            name="emergency_2_name"
            style={{ width: "100%" }}
          >
            <Input
              // onKeyPress={(e) => {
              //   const charCode = e.which || e.keyCode;
              //   if (charCode >= 48 && charCode <= 57) {
              //     e.preventDefault();
              //   }
              // }}
              onKeyDown={(e) => {
                // Allow Backspace and Enter keys
                if (e.key === "Backspace" || e.key === "Enter") {
                  return;
                }
                // Allow only alphabetic characters and spaces
                if (!/^[a-zA-Z ]$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) =>
                onInputHandleChange("emergency_2_name", e.target.value)
              }
            />
          </Form.Item>
        </div>
        <div className="besicFormInnerDiv">
          <Form.Item
            label="Relationship*"
            name="emergency_2_relationship"
            style={{ width: "100%" }}
          >
            {/* <Input
              onChange={(e) =>
                onInputHandleChange("emergency_2_relationship", e.target.value)
              }
            /> */}
            <Select
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp="children"
            showSearch
              style={{ width: "100%" }}
              onChange={(e) =>
                onInputHandleChange("emergency_2_relationship", e)
              }
            >
              <Select.Option value="Parent">Parent</Select.Option>
              <Select.Option value="Legal Guardian">
                Legal Guardian
              </Select.Option>
              <Select.Option value="Spouse">Spouse</Select.Option>
              <Select.Option value="Significant Other">
                Significant Other
              </Select.Option>
              <Select.Option value="Child">Child</Select.Option>
              <Select.Option value="Friend">Friend</Select.Option>
              <Select.Option value="Case Worker">Case Worker</Select.Option>
              <Select.Option value="Primary Care Provider">
                Primary Care Provider
              </Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="basicFormRow">
        <div className="besicFormInnerDiv">
          <Form.Item
            label="Mobile Number*"
            name="emergency_2_mobile_number"
            style={{ width: "100%" }}
          >
            <Input
              onChange={(e) =>
                onInputHandleChange("emergency_2_mobile_number", e.target.value)
              }
              onKeyPress={(e) => {
                const charCode = e.which || e.keyCode;
                if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                  e.preventDefault();
                }
              }} 
            />
          </Form.Item>
        </div>
        <div className="besicFormInnerDiv">
          <Form.Item
            label="Home Contact Number"
            name="emergency_2_home_contact_number"
            style={{ width: "100%" }}
          >
            <Input
              onKeyPress={(e) => {
                const charCode = e.which || e.keyCode;
                if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                  e.preventDefault();
                }
              }} 
              onChange={(e) =>
                onInputHandleChange(
                  "emergency_2_home_contact_number",
                  e.target.value
                )
              }
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
}

export default NextofKinForm;
