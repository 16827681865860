import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import {Elements} from '@stripe/react-stripe-js';
import { Provider } from "react-redux";
import { store } from "./redux/store";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
      <Provider store={store}>
      <App />
      </Provider>
    // </React.StrictMode>
);
