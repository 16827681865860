import { Checkbox, Divider, Form, Radio, Select, message } from "antd";
import React,{useEffect, useState} from 'react'
import MembershipFormSubmission from "./MembershipFormSubmission";
import axios from "axios";
// import Pdf from "../../Assets/Membership Terms and Conditions.pdf"

function DeclarationForm({formData, onInputHandleChange }) {
  const [value, setValue] = useState(""); 
  const [Pdf, setPdf] = useState("")

  const getTermsAndConditions = async () => {
    try{
      const res = await axios.post( `${process.env.REACT_APP_BASE_URL}/cms`,{
        name:"terms_and_conditions"
      })
      setPdf(res.data.result.content);
    }catch(err){
      message.error(err.message)
    }
  }
  const onChange = (e) => {
    console.log(e.target.value)
    setValue(e.target.value);
  };
 

  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
    getTermsAndConditions()
   }, [])
  return (
    <div className="basicFormRow">
      <div className="besicFormInnerDiv">
        {/* <p className="font-weight-bold">Membership Term</p> */}
        {/* <Form.Item>
          <Radio.Group
            onChange={(e) =>
              onInputHandleChange("membership_term", e.target.value)
            }
          >
            <Radio value={"new"}>New Member</Radio>
            <Radio value={"renewal"}>Renewal</Radio>
          </Radio.Group>
        </Form.Item> */}
        {/* <Form.Item
          label="Term Years* "
          name="terms_years"
          style={{ width: "100%" }}
        >
          <Select
            style={{ width: "100%" }}
            onChange={(e) => onInputHandleChange("terms_years", e)}
          >
            {formData.gender!=="female"&&<Select.Option value={6}>6 Months</Select.Option>}
            <Select.Option value={12}>1 Year</Select.Option>
            <Select.Option value={24}>2 Year</Select.Option>
            <Select.Option value={36}>3 Year</Select.Option>
            <Select.Option value={48}>4 Year</Select.Option>
            <Select.Option value={60}>5 Year</Select.Option>
          </Select>
        </Form.Item> */}

        {/* <Form.Item
          label="Do you want Physical Card* "
          name="is_membership_card"
          style={{ width: "100%" }}
        >
    
          <Radio.Group
          onChange={(e)=>{
            onChange(e)
             onInputHandleChange("is_membership_card", e.target.value)
          }} value={value}>
        <Radio value={1}>Yes</Radio>
        <Radio value={0}>No</Radio>
      </Radio.Group>
          
        </Form.Item> */}

        <p className="font-weight-bold">Declaration</p>
        {/* <p>Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.</p>
                <p>Date :</p>
                <Divider style={{ width: "40px" }} /> */}
        <div className="d-flex align-items-center">
        <Form.Item
          style={{ marginBottom: "unset" }}
          className="mr-3"
          name="remember"
          valuePropName="checked"
          rules={[{ required: true, message: 'Please check this box' }]}
        >
          <Checkbox onChange={(e) => onInputHandleChange("remember", e.target.checked)} />
        </Form.Item>
          <p className="m-0">
            I/We declare that the information given in this membership form is
            true and correct to the best of my knowledge and belief.
          </p>
        </div>
        <div className="d-flex align-items-center">
        <Form.Item
          name="remember2"
          className="mr-3"
          style={{ marginBottom: "unset" }}
          valuePropName="checked"
          rules={[{ required: true, message: 'Please check this box' }]}
        >
          <Checkbox onChange={(e) => onInputHandleChange("remember2", e.target.checked)} />
        </Form.Item>
          <p className="m-0">
              I agree to the <a style={{display:"inline"}} target="_blank" href={Pdf}>Terms and Conditions</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default DeclarationForm;
