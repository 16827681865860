import React, { useEffect, useRef, useState } from "react";
import styles from"./heroSection.module.css";
import { motion, useInView } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
import { Link } from "react-router-dom";


function HeroSection() {

    const ref = useRef()
    const inView = useInView(ref, { once: true })

    const [currSlide, setCurrSlide] = useState(0)

    // console.log(currSlide)

    const [hideFirst, sethideFirst] = useState(false)

    useEffect(() => {
        // console.log(inView)
        setTimeout(() => {
            sethideFirst(true)
        }, 13000);
    }, [inView])


    return (

        <div className={styles.heroContainer}>

            <Swiper
            className={styles.heroSwiper}
                spaceBetween={30}
                effect={"fade"}
                centeredSlides={true}
                autoplay={{
                    delay: 13000,
                    disableOnInteraction: false,

                }}

                loop={true}
                onSlideChange={(swiperCore) => {
                    const {
                        activeIndex,
                        snapIndex,
                        previousIndex,
                        realIndex,
                    } = swiperCore;
                    setCurrSlide(realIndex)
                }}
                pagination={{
                    clickable: true,
                }}
                // navigation={true}

                modules={[EffectFade,Autoplay, Pagination]}
                
            >
                {!hideFirst ? <SwiperSlide>
                    <div ref={ref}
                        
                        className={styles.heroImgContainer}>
                        <img style={{
                            transform: inView ? "scale(1.14)" : "scale(1)",
                            transition: inView ? "all 15s" : "none",
                        }} className={styles.heroImg} src="images/sia-heroslider-1.JPG" alt="" />
                        {/* <img className="heroImg" src="images/slider3-1.jpg" alt="" /> */}
                    </div>
                    <motion.div >
                        <div
                        className={styles.heroContentWrapper}
                        style={{
                            opacity: inView ? "0.2" : "0.8",
                            transition : inView ? "all 5s":"none",
                        }}>
                        </div>
                        <div style={{
                            transform: inView ? "translateX(-50%) translateY(-50%)" : "translateX(-50%) translateY(-40%)",
                            opacity: inView ? "1" : "0",
                            transition: "all 3s"
                        }} className={styles.overlayHero}>
                        <p className={styles.heroText}>Welcome to <br /> Singapore Indian Association</p>
                        <Link to='history' className={styles.heroBtn}>Know More</Link>
                        </div>
                    </motion.div>
                </SwiperSlide> :
                    <SwiperSlide>
                        <div
                            className={styles.heroImgContainer}>
                          
                            <img style={{
                                transform: currSlide === 0 ? "scale(1.14)" : "scale(1)",
                                transition: currSlide === 0 ? "all 15s" : "none",
                            }} className={styles.heroImg} src="images/sia-heroslider-1.JPG" alt="" />
                            {/* <img className="heroImg" src="images/slider3-1.jpg" alt="" /> */}
                        </div>
                        <motion.div>
                            <div
                            className={styles.heroContentWrapper}
                           
                            style={{
                                opacity: currSlide === 0 ? "0.2" : "0.8",
                                transition : currSlide===0 ? "all 5s":"none",
                            }}>
                            </div>
                            <div style={{
                                transform: currSlide === 0 ? "translateX(-50%) translateY(-50%)" : "translateX(-50%) translateY(-40%)",
                                opacity: currSlide === 0 ? "1" : "0",
                                transition: "all 3s"
                            }} className={styles.overlayHero}>
                                <p className={styles.heroText}>Welcome to <br /> Singapore Indian Association</p>
                                <Link to='history' className={styles.heroBtn}>Know More</Link>
                            </div>
                        </motion.div>
                    </SwiperSlide>
                }
                <SwiperSlide>
                    <div
                        className={styles.heroImgContainer}
                        >
                        <img style={{
                            transform: currSlide === 1 ? "scale(1.14)" : "scale(1)",
                            transition: currSlide === 1 ? "all 15s" : "none",
                        }} className={styles.heroImg} src="images/sia-heroslider-2.JPG" alt="" />
                        {/* <img className="heroImg" src="images/slider3-1.jpg" alt="" /> */}
                    </div>
                    <motion.div>
                        <div
                        className={styles.heroContentWrapper}
                        
                        style={{
                            opacity: currSlide === 1 ? "0.2" : "0.8",
                            transition : currSlide===1 ? "all 5s":"none",
                        }}>
                        </div>
                        <div style={{
                            transform: currSlide === 1 ? "translateX(-50%) translateY(-50%)" : "translateX(-50%) translateY(-40%)",
                            opacity: currSlide === 1 ? "1" : "0",
                            transition: "all 3s"
                        }} className={styles.overlayHero}>
                        <p className={styles.heroText}>A Place For Our Community <br /> Engagement & Sports <br /> Development</p>
                        <Link to='upcoming-events' className={styles.heroBtn}>Visit Our Events</Link>
                        </div>
                    </motion.div>
                </SwiperSlide>
                <SwiperSlide>
                    <div
                        className={styles.heroImgContainer}
                        >
                        {/* <img className="heroImg" src="images/sia-heroslider-1.JPG" alt="" /> */}
                        <img style={{
                            transform: currSlide === 2 ? "scale(1.14)" : "scale(1)",
                            transition: currSlide === 2 ? "all 15s" : "none",
                        }} className={styles.heroImg} src="images/slider3-1.jpg" alt="" />
                    </div>
                    <motion.div >
                    <div
                     className={styles.heroContentWrapper}
                           
                            style={{
                                opacity: currSlide === 2 ? "0.2" : "0.8",
                                transition : currSlide === 2 ? "all 5s":"none",
                            }}>
                            </div>
                            <div style={{
                                transform: currSlide === 2 ? "translateX(-50%) translateY(-50%)" : "translateX(-50%) translateY(-40%)",
                                opacity: currSlide === 2 ? "1" : "0",
                                transition: "all 3s"
                            }} className={styles.overlayHero}>
                        <p className={styles.heroText}>Time to Celebrate Our History  <br /> & Build A Legacy For Future</p>
                        <Link to='galleryFolder' className={styles.heroBtn}>View Gallery</Link>
                        </div>
                    </motion.div>
                </SwiperSlide>

            </Swiper>

        </div >
    )
}

export default HeroSection;