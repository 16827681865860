import { Button, Input, Form, message, Modal } from "antd";
import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsExpire } from "../../redux/slice/IsExpireSlice";

function Email() {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const onFinishFailed = (errorInfo) => {};
  const dispatch = useDispatch()
  const onFinishSendEmail = async (values) => {
    try {
      setLoading(true)
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/v2/forget-password`, values);
      if(res.data.success){
        message.success("Please check your email for reset password link")
      }
      else{
        message.error(res.data.message)
      }
      setVisible(false)
      form2.resetFields()
      setLoading(false)
    } catch (err) {
      message.error(err.response.data);
      setLoading(false)
    }
  }
  const onFinish = async (values) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/v2/member-login`, values);
      if(res.data.status===false){
        message.error(res.data.message);
        return;
      }
      localStorage.setItem("token", res.data.token.token);
      if(res.data.isExpired){
        console.log(res.data.isExpired);
       dispatch(setIsExpire({
          isExpire:res.data.isExpired,
          expireDate:res.data.member.expiry_date,
          plan:res.data.member.name,
          id:res.data.member.id,
          category:res.data.member.category
       }));
     }
      navigate("/MemberProfilePage");
    } catch (err) {
      message.error("Wrong Email or Password");
      console.log(err)
    //   message.error("Something went wrong");
    }
  };
  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="off"
      >
        <h4 className="mb-3">Please Enter Your Registered Email !</h4>
        <Form.Item name="email" rules={[
          {
            required: true,
            message: 'Please Input Your Email!',
          },
          {
            type: "email",
            message: "Please enter a valid email"
          }
        ]} autocomplete="one-time-code">
          <Input placeholder="Enter Your Email" />
        </Form.Item>
        <h4 className="mb-3">Please Enter Your Password !</h4>
        <Form.Item name="password" rules={[
          {
            required: true,
            message: 'Please Input Your Password!',
          }
        ]}>
          <Input.Password
            placeholder="Enter Your Password"
            autocomplete="one-time-code"
          />
        </Form.Item>
        <div style={{marginTop:"-20px",cursor:"pointer",fontSize:"12px"}} onClick={()=>setVisible(true)} className="text-right">Reset Password</div>
        <Form.Item>
          <Button
            // className="log_in_btn"
            style={{
              color: "#fff",
              cursor: "pointer",
              fontSize: "1.8rem",
              height: "4rem",
              justifyContent: "center",
              marginTop: "80px",
              width: "100%"
            }}
            // style={{width:"100%"}}
            type="danger"
            htmlType="submit"
          >
            Log In
          </Button>
        </Form.Item>
      </Form>
      <Modal className="modal-email" title={"Reset Password"} visible={visible} centered destroyOnClose  onCancel={()=>{
              setVisible(false)
              form2.resetFields()
            }} footer={false} >

        <div>
        <Form  form={form2} onFinish={onFinishSendEmail}>
        <h4 className="mb-3" >Please Enter Your Registered Email !</h4>
          <Form.Item name="email" rules={[{
            required: true,
            message: 'Please Input Your Email!',
          },
          {
            type: "email",
            message: "Please enter a valid email"
          }]}  autocomplete="one-time-code">
            <Input placeholder="Enter Your Email" />
          </Form.Item>
          <Form.Item className="text-right"  >
            <Button onClick={()=>{
              setVisible(false)
              form2.resetFields()
            }}>
              Cancel
            </Button>
            <Button
            loading={loading}
              className="ml-2"
              type="danger"
              htmlType="submit"
            >
             Continue
            </Button>
          </Form.Item>
        </Form>
        </div>
      </Modal>
    </>
  );
}

export default Email;
