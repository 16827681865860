import React from "react";
import "./edit_profile.css";
import { Button, DatePicker, Form, Input, Select, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import { Option } from "antd/lib/mentions";
import country_codes from "../MembershipForm/countryCodes";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const EditProfile = () => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState();
  const [fileList, setFileList] = useState();
  const storedToken = localStorage.getItem("token");
  const [first_name, setFirst_name] = useState("");
  const [countryCodes, setCountryCodes] = useState("");
  const [defData, setDefData] = useState({});
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState(null);
  const [form] = Form.useForm();
  const handleImageChange = async (info) => {
    const file = info.fileList[0]?.originFileObj;
    // console.log("test",info.file.size < 500000);
    if (info.file.size > 500000) {
      console.log("test");
      message.error("please upload image under 500 kb !");
    }
    if (file) {
      // let formData = new FormData();
      // formData.append("file", file);
      // formData.append("type", "new_member");
      setFileList(file);
      // console.log(formData);
    }
    if (info?.fileList[0]) {
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
    }
  };
  const thumbnailImage = async () => {
    let imageUrl = "";

    const formData = new FormData();
    console.log(fileList);
    formData.append("file", fileList);
    formData.append("type", "new_member");
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/upload-images`,
      data: formData,
      // headers: {
      //   "content-type": "application/json",
      //    Authorization: localStorage.getItem('token')
      // },
    })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          console.log("response", response.data.url);
          imageUrl = response.data.url;
          // setImagesList([...imagesList, { url: response.data.url }])
          // return true;
        }
        // return false
      })
      .catch(function (error) {
        console.log(error);
        // return false
      });

    return imageUrl;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const onFinish = async (values) => {
    if (!values.Full_Name || values.email || values.phone || !values.dob || !values.address || !values.next_of_kin) {
      if (!values.Full_Name) {
        message.error('Please Fill Full Name !')
        return
      }
      if (!values.email) {
        message.error('Please Fill Email !')
        return
      }
      if (!values.phone) {
        message.error('Please Fill Phone !')
        return
      }
      if (!values.dob) {
        message.error('Please Fill Date of birth !')
        return
      }
      if (!values.address) {
        message.error('Please Fill Address !')
        return
      }

      if(!values.next_of_kin){
        message.error('Please Fill Next of Kin');
        return
      }
      
    }
    console.log({
      id: defData.id,
      first_name: values.Full_Name,
      dob: moment(values.dob, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
      email: values.email,
      phone: values.phone,
      image: imageUrl,
      country_code: defData.country_code,
    });
    const data = await thumbnailImage();
    const res1 = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/edit-user-profile`,
      {
        id: defData.id,
        first_name: values.Full_Name,
        date_of_birth: moment(values.dob, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
        email: values.email,
        phone: values.phone,
        image: !data ? defData.image : data,
        country_code: countryCodes,
        address:values.address,
        next_of_kin:values.next_of_kin
      },
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    );
    console.log(res1);
    if(res1.data.success){
      navigate("/MemberProfilePage")
    }
  };
  const selectBefore = (
    <Form.Item className="m-0" style={{ width: "100%" }}>
      <Select
      disabled={true}
        // defaultValue={countryCodes}
        value={countryCodes}
        style={{
          width: "80px",
        }}
        onChange={(e) => setCountryCodes(e)}
      >
        {" "}
        {country_codes.map((elem, i) => {
          return (
            <>
              <Option key={i} value={elem.code}>
                {elem.code}
              </Option>
            </>
          );
        })}
        {/* <Option value="+65">+65</Option>
      <Option value="+91">+91</Option> */}
      </Select>
    </Form.Item>
  );
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get-member-details`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      setDefData(response.data.user);
      setCountryCodes(response.data.user.country_code);
      form.setFieldsValue({
        Full_Name: response.data.user.first_name,
        phone: response.data.user.phone,
        email: response.data.user.email,
        country_code:`+${response.data.user.country_code}`,
        dob: moment(
          response.data.user.date_of_birth,
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        ),
        address:response.data.user.address,
        next_of_kin:response.data.user.next_of_kin,
      });
      setImageUrl(response.data.user.image);
      console.log(response.data.user);
    } catch (error) {
      console.log(error);
    }
  };
  const disabledDate = (current) => {
    // Calculate the minimum date for someone who is above 18 years old
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);

    // Disable all dates that are after the minimum date
    return current && current > minDate;
  };
  useEffect(() => {
    if (!first_name) {
      fetchData();
    }
  }, [countryCodes]);
  return (
    <div className="marginTopFromNavbar">
      <div className="container d-flex justify-content-center align-items-center">
        <div className="editParentBox p-4" style={{ width: "100%" }}>
          <Form
            form={form}
            onFinish={onFinish}
            style={{ fontWeight: "500" }}
            layout="vertical"
          >
            <div className="basicFormRow">
              <div className="besicFormInnerDiv">
                <Form.Item label="Upload Photo" name="pic">
                  {/* <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload> */}
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={() => false}
                    maxCount={1}
                    onChange={handleImageChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </div>
              <div></div>
            </div>
            <div className="basicFormRow">
              <div className="besicFormInnerDiv">
                <Form.Item
                  label="Full Name"
                  name="Full_Name"
                  style={{ width: "100%" }}
                >
                  <Input onKeyPress={(e) => {
                const charCode = e.which || e.keyCode;
                if (charCode >= 48 && charCode <= 57) {
                  e.preventDefault();
                }
              }} />
                </Form.Item>
              </div>
              <div className="besicFormInnerDiv">
                <Form.Item
                  label="Date of Birth"
                  name="dob"
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    onChange={(e) => console.log("join_date", e)}
                    style={{ width: "100%" }}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="basicFormRow">
              <div className="besicFormInnerDiv">
                <Form.Item
                  label="Mobile Number*"
                  name="phone"
                  style={{ width: "100%" }}
                >
                  <Input disabled={true} onKeyPress={(e) => {
  const charCode = e.which || e.keyCode;
  if ((charCode < 48 || charCode > 57) && charCode !== 8) {
    e.preventDefault();
  }
}} addonBefore={selectBefore} />
                </Form.Item>
              </div>
              <div className="besicFormInnerDiv">
                <Form.Item
                  label="Email Address"
                  name="email"
                  style={{ width: "100%" }}
                >
                  <Input disabled={true} />
                </Form.Item>
              </div>
            </div>
            <div className="basicFormRow">
              <div className="besicFormInnerDiv">
            <Form.Item
                  label="Address"
                  name="address"
                  style={{ width: "100%" }}
                >
                  {/* <DatePicker
                    onChange={(e) => console.log("join_date", e)}
                    style={{ width: "100%" }}
                    disabledDate={disabledDate}
                  /> */}
                  <Input onKeyPress={(e) => {
                const charCode = e.which || e.keyCode;
                if (charCode >= 48 && charCode <= 57) {
                  e.preventDefault();
                }
              }} />
                </Form.Item>
              </div>
             
              <div className="besicFormInnerDiv">
            <Form.Item
                  label="Next of Kin"
                  name="next_of_kin"
                  style={{ width: "100%" }}
                >
                  <Input onKeyPress={(e) => {
                const charCode = e.which || e.keyCode;
                if (charCode >= 48 && charCode <= 57) {
                  e.preventDefault();
                }
              }} />
                </Form.Item>
                </div>
                </div>
            <Form.Item>
              <Button htmlType="submit" type="danger">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
