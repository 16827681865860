import React, { useEffect } from 'react'
import Close from "../Assets/close_1828665 1.svg"
import axios from 'axios'
import { message } from 'antd'

function FailPayment() {

  const paymentStatus = async () => {
    try{
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-status-by-payment-id`,{
        
                "payment_id": localStorage.getItem("payment_id"),
            // user_id:
          },{
            headers:{
              "content":"application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
          
          })
          
          localStorage.removeItem("payment_id")
        
    }catch(err){
        console.log(err)
        message.error("Something went wrong")
    }

}

useEffect(()=>{
    paymentStatus();
},[])

  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"110px"}}>
      <div style={{
      boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.15)",
      padding:"71px 31px 134px 38px",
      width:"80%"
      }}>
        <div style={{
          borderRadius:"133px",
          background: "#FEEBEA",
          width:"200px",
          height:"200px",
          margin:"auto",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
        }}>
        <img src={Close} alt="close" />
        </div>
        <div style={styles.text}>Payment Failed.</div>
        <div style={styles.text}>Please review your payment details and try again.</div>
      </div>
    </div>
  )
}

export default FailPayment

const styles={
  text:{
    fontSize:"32px",
    fontWeight:"bold",
    textAlign:"center",
  }
}