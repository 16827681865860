import React from 'react'

function MembershipFormSubmission() {
  return (
    <div className='Review_confirm'> 
            <div className='tick_icon'>
                <img src="/images/Icon.png"/>
            </div>
            <div className='review_msg_1'>
            Thank you for showing interest in Sports Club Membership. Our board will review your membership request and update you via call/email upon confirmation of your membership.
            </div>
            {/* <div className='review_msg_2'>
            You would be given period of 30 days to complete payments after sports membership approval.
            </div> */}
        </div>
  )
}

export default MembershipFormSubmission