import { Button, message } from "antd";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import Pdf from "../../Assets/Membership Terms and Conditions.pdf";
import "./MembershipCheckout.css";
import { paymentUrl } from "../../Services/BusinessLogic";
import { useDispatch } from "react-redux";
import { setPayment } from "../../redux/slice/paymentIntentSlice";
import { calculatePercent } from "../../utils/GST";

function MembershipCheckout() {
  const storedToken = localStorage.getItem("token");
  const [nName, setnName] = useState("");
  const [loading, setLoading] = useState(false);
  const [nEmail, setnEmail] = useState("");
  const [nPhone, setnPhone] = useState("");
  const [check, setCheck] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const phone = localStorage.getItem("phone");
  const elem = location.state;

  const [Pdf, setPdf] = useState("");

  const getTermsAndConditions = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/cms`, {
        name: "terms_and_conditions",
      });
      setPdf(res.data.result.content);
    } catch (err) {
      message.error(err.message);
    }
  };

  const calculateGST = (amount, tax, type) => {
    return parseFloat(calculatePercent(tax, amount , type));
  };
  useEffect(() => {
    if (!elem) {
      navigate("/Membership");
      return;
    }
  }, [elem]);

  const paymentApi = async (member_id, type, total_amount) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/payments/new`,
        {
          membership_id: member_id,
          payment_intent_id: "123",
          payment_status: "paid",
          payment_mode: "online",
          type: type,
          total_amount: total_amount,
          user_id: 123123123,
          // user_id:
        },
        {
          headers: {
            content: "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      console.log(response.data);
      return true;
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const onBooking = async () => {
    if (check) {
      // const payment = await paymentApi(elem.member_id,"membership",elem.amount)
      setLoading(true);
      const Link = await paymentUrl(
        elem.membership_id,
        localStorage.getItem("user_id"),
        "membership",
        elem.amount,
        elem.membership_name,
        elem.id,
        null,
        calculateGST(elem.amount, elem.tax, elem.taxType),
        calculateGST(elem.amount, elem.serviceCharge, elem.serviceType)
      );
      if (Link) {
        dispatch(setPayment(Link.paymentId));
        window.location.href = Link.paymentLink;
        // navigate(`/MembershipConfirmed?bookingName=${elem.membership_name}`);
      } else {
        // message.error("Something went wrong")
      }
      setLoading(false);
    } else {
      message.error("Please Check to acknowledge our Privacy & Terms Policy");
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
    });
    getTermsAndConditions();
  }, []);
  return (
    <>
      {elem ? (
        <div className="marginTopFromNavbar">
          <div className="checkout">
            <div className="membership_summary">
              <div className="membership_head">Membership Plan Summary</div>
              <div className="membership_plan">
                <div className="membership_plan_">
                  <div>{elem.membership_name}</div>
                  <div>S${elem.amount?.toFixed(2)}</div>
                </div>
              </div>
              <div className="checkout_price">
                <div>
                  <div className="price_title">Subtotal</div>
                  <div className="price">S${elem.amount?.toFixed(2)}</div>
                </div>
                <div>
                  <div className="price_title">GST</div>
                  <div className="price">
                    S$
                    {calculatePercent(
                      elem.tax,
                      elem.amount,
                      elem.taxType
                    )?.toFixed(2)}
                  </div>
                </div>
                <div>
                  <div className="price_title">Service Charge</div>
                  <div className="price">
                    S$
                    {calculatePercent(
                      elem.serviceCharge,
                      elem.amount,
                      elem.serviceType
                    )?.toFixed(2)}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <div className="price_title">Discount</div> */}
                  {/* <div className="price_title">Discount(10%)</div> */}
                  {/* <div className="price">-S${0}.00</div> */}
                  <div className="price_title">Total</div>
                  <div className="price">
                    S$
                    {elem.amount &&
                      parseFloat(
                        parseFloat(elem.amount?.toFixed(2)) +
                          parseFloat(
                            calculatePercent(
                              elem.tax,
                              elem.amount,
                              elem.taxType
                            )
                          ) +
                          parseFloat(
                            calculateGST(
                              elem.amount,
                              elem.serviceCharge,
                              elem.serviceType
                            )
                          )
                      ).toFixed(2)}
                  </div>
                </div>
              </div>
              <div className="t_and_c">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox"
                  style={{ top: "-2px" }}
                  value={check}
                  onChange={(e) => setCheck(e.target.checked)}
                />
                <div>
                  Please check to acknowledge our{" "}
                  <a style={{ display: "inline" }} target="_blank" href={Pdf}>
                    Privacy & Terms Policy
                  </a>
                </div>
              </div>
              <div onClick={onBooking}>
                <Button
                  type="primary"
                  danger
                  loading={loading}
                  style={{ width: "100%", height: "50px" }}
                  className="text-center d-block"
                >
                  Pay S$
                  {elem.amount &&
                    parseFloat(
                      elem.amount +
                        calculateGST(elem.amount, elem.tax, elem.taxType) +
                        calculateGST(
                          elem.amount,
                          elem.serviceCharge,
                          elem.serviceType
                        )
                    ).toFixed(2)}
                </Button>
              </div>
            </div>

            {/* <div className="checkout_form">
          <div style={{display:"none"}}>
            <label>Name</label>
            <input
              className="login-input"
              type="text"
              name=""
              id=""
              placeholder="Name"
              value={name ? name : nName}
              onChange={(e) => {
                if (name) {
                  return;
                }
                setnName(e.target.value);
              }}
            />
          </div>
         
          <div style={{display:"none"}}>
            <label>Phone number</label>
            <input
              type="Text"
              className="login-input"
              placeholder="Phone number"
              value={phone ? phone : nPhone}
              onChange={(e) => {
                if (phone) {
                  return;
                }
                setnPhone(e.target.value);
              }}
            />
          </div> 
          <div
            style={{
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "165%",
              letterSpacing: "0.005em",
              color: "#111729",
              flexDirection: "column",
            }}
          >
            <label>Card Number</label>
            <input
              className="login-input"
              id="ccn"
              type="tel"
              inputmode="numeric"
              pattern="[0-9\s]{13,19}"
              autocomplete="cc-number"
              maxlength="19"
              placeholder="1234 1234 1234 1234"
            />
          </div>
          <div className="card_info">
            <div>
              <label>Expiry</label>
              <input placeholder="MM/YY" />
            </div>
            <div>
              <label>CVV</label>
              <input type="numericmode" placeholder="CVV" />
            </div>
          </div>

          <div className="card_info d-flex justify-content-between mt-2">
            <div
              style={{
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "165%",
                letterSpacing: "0.005em",
                color: "#111729",
                flexDirection: "column",
              }}
            >
              <label>Country</label>
              <input type="Country" placeholder="Country" />
            </div>
            <div>
              <label>Postal Code</label>
              <input inputmode="numeric" placeholder="Postal Code" />
            </div>
          </div>
          <div className="t_and_c">
            <input
              type="checkbox"
              name=""
              id="checkbox"
              onChange={(e) => setCheck(e.target.checked)}
            />
            <div>
              Please check to acknowledge our <a style={{display:"inline"}} target="_blank" href={Pdf}>Privacy & Terms Policy</a>
            </div>
          </div>
          <div onClick={onBooking}>
            <button className="text-center d-block">
              Pay S${elem.amount && elem.amount}.00
            </button>
          </div>
        </div> */}
          </div>
        </div>
      ) : (
        <div>Loading</div>
      )}
    </>
  );
}

export default MembershipCheckout;
